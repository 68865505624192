<template>
  <div class="row justify-center items-center">
    <q-form @submit="createAccount(form)">
      <q-card
        class="register-account"
        :bordered="!$q.platform.is.mobile"
        flat
        :class="$q.platform.is.mobile ? 'q-pa-sm  transparent' : 'q-pa-md'"
        :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : 'width: 512px'"
      >
        <q-card-section class="text-center q-pt-md q-pb-none">
          <q-img
            :src="require('@/assets/images/logo_color.png')"
            spinner-color="white"
            width="168px"
            spinner-size="82px"
          />
        </q-card-section>
        <q-card-section class="q-col-gutter-md">
          <div class="row q-col-gutter-md">
            <div class="col">
              <q-input
                clearable
                clear-icon="close"
                ref="first_name"
                outlined
                :label="$t('name')"
                v-model="form.first_name"
                lazy-rules
                :rules="[isRequired]"
              />
            </div>
            <div class="col">
              <q-input
                clearable
                clear-icon="close"
                ref="last_name"
                outlined
                :label="$t('last_name')"
                v-model="form.last_name"
                lazy-rules
                :rules="[isRequired]"
              />
            </div>
          </div>
          <q-input
            clearable
            clear-icon="close"
            ref="email"
            outlined
            type="email"
            label="Email"
            v-model="form.email"
            lazy-rules
            :rules="[isRequired, isEmail]"
          >
          </q-input>
          <q-input
            clearable
            clear-icon="close"
            outlined
            :label="$t('password')"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            lazy-rules
            :rules="[isRequired, isPassword]"
          >
            <template v-slot:append>
              <q-btn
                flat
                no-caps
                :label="showPassword ? $t('hide') : $t('show')"
                @click="showPassword = !showPassword"
              />
            </template>
            <!-- <q-tooltip content-class="bg-primary text-white">
                            <q-list dense separator>
                                <q-item-label header class="text-white">
                                    Sua senha deve conter no mínimo:
                                    <ul>
                                        <li
                                            :class="
                                                checkPassword.sizeMinimo
                                                    ? 'text-positive'
                                                    : 'text-white'
                                            "
                                        >
                                            8 Caracteres
                                        </li>
                                        <li
                                            :class="
                                                checkPassword.maiusculo
                                                    ? 'text-positive'
                                                    : 'text-white'
                                            "
                                        >
                                            1 Letra Maiúscula
                                        </li>
                                        <li
                                            :class="
                                                checkPassword.minusculo
                                                    ? 'text-positive'
                                                    : 'text-white'
                                            "
                                        >
                                            1 Letra minúscula
                                        </li>
                                        <li
                                            :class="
                                                checkPassword.numero
                                                    ? 'text-positive'
                                                    : 'text-white'
                                            "
                                        >
                                            1 Número
                                        </li>
                                        <li
                                            :class="
                                                checkPassword.caracter
                                                    ? 'text-positive'
                                                    : 'text-white'
                                            "
                                        >
                                            1 Caractere Especial
                                        </li>
                                    </ul>
                                </q-item-label>
                            </q-list>
                        </q-tooltip> -->
          </q-input>
          <div>
            <q-linear-progress
              class="abslute"
              style="top: -10px"
              size="25px"
              :color="verifyPasswordStrength.color"
              :value="verifyPasswordStrength.value"
            >
              <div class="absolute-full flex flex-center" v-if="verifyPasswordStrength.label">
                <q-badge
                  color="white"
                  :text-color="verifyPasswordStrength.color"
                  :label="verifyPasswordStrength.label"
                />
              </div>
            </q-linear-progress>
          </div>
          <q-input
            clearable
            clear-icon="close"
            ref="checkPassword"
            outlined
            :label="$t('confirm_pass')"
            v-model="form.checkPassword"
            :type="showCheckPassword ? 'text' : 'password'"
            lazy-rules
            :rules="[isRequired]"
          >
            <template v-slot:append>
              <q-btn
                flat
                no-caps
                :label="showCheckPassword ? $t('hide') : $t('show')"
                @click="showCheckPassword = !showCheckPassword"
              />
            </template>
          </q-input>
        </q-card-section>
        <div class="q-px-md q-pb-md">
          <vue-recaptcha sitekey="6LfY4HwjAAAAAGeM8BCS3CDIxW71QPsJusc82dvJ" ref="recaptcha" @verify="verifyMethod" />
          <!-- <vue-recaptcha
                        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        ref="recaptcha"
                        @verify="verifyMethod"
                    >
                    </vue-recaptcha> -->
        </div>
        <q-card-section class="q-px-md row justify-between q-pt-none">
          <q-btn
            unelevated
            outline
            no-caps
            color="primary"
            class="q-py-sm q-px-lg"
            :label="$t('cancel')"
            to="/auth/login"
          />
          <q-btn
            unelevated
            color="primary"
            no-caps
            class="q-py-sm q-px-lg"
            :label="$t('save')"
            :disabled="!checkCaptcha"
            type="submit"
          />
        </q-card-section>
        <q-card-section class="row q-col-gutter-x-sm q-px-xs q-py-none">
          <div class="col-8">
            <q-toggle v-model="checkTerms" :label="$t('i agree to the terms')" disable />
          </div>
          <div class="col-4 text-right">
            <q-btn flat color="primary" no-caps :label="$t('terms')" @click="dialogTerm = true" />
          </div>
        </q-card-section>
      </q-card>
    </q-form>
    <q-dialog v-model="dialogTerm" persistent>
      <q-card style="width: 800px; max-width: 80vw">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            {{ $t('terms of use') }}
          </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="checkTerms = false" v-close-popup />
        </q-toolbar>
        <q-scroll-area style="height: 70vh; width: 100%" class="q-px-md" ref="terms" @scroll="onScroll">
          <component :is="termsName" />
        </q-scroll-area>
        <q-separator />
        <q-card-section class="row justify-between">
          <q-checkbox
            v-model="checkTerms"
            :label="$t('i have read and agree to the terms of use')"
            color="positive"
            keep-color
            :disable="!checkButton"
          />
          <a v-if="this.$i18n.locale == 'pt-br'" href="/pdf/termos_pt.pdf" style="margin-top: 10px" download
            >Fazer download do pdf</a
          >
          <a v-else-if="$i18n.locale == 'en'" href="/pdf/terms_en.pdf" style="margin-top: 10px" download
            >Download pdf</a
          >
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            outline
            :label="$t('cancel')"
            color="negative"
            no-caps
            class="q-px-md"
            @click="checkTerms = false"
            v-close-popup
          />
          <q-btn
            :disabled="!checkTerms"
            unelevated
            :label="$t('accepted')"
            color="primary"
            no-caps
            class="q-px-md"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import AccountMixin from '@/mixins/AccountMixin';
import { VueRecaptcha } from 'vue-recaptcha';
// import pdf from "vue-pdf";
import TermsPt from './TermsPt.vue';
import TermsEn from './TermsEn.vue';
export default {
  name: 'Register',
  mixins: [AccountMixin],
  components: {
    // pdf,
    VueRecaptcha,
    TermsPt,
    TermsEn
  },
  data() {
    return {
      page: 1,
      currentPage: 0,
      pageCount: 0,
      step: 'person',
      dialogTerm: false,
      checkTerms: false,
      checkCaptcha: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: '',
        checkPassword: null
      },
      accept: false,
      termsDialog: false,
      showPassword: false,
      showCheckPassword: false,
      loading: false,
      checkButton: false
    };
  },
  computed: {
    verifyPasswordStrength() {
      const score = this.scorePassword(this.form.password);
      if (score > 80) return { label: 'Forte', value: 1, color: 'positive' };
      if (score > 60) return { label: 'Boa', value: 0.7, color: 'info' };
      if (score >= 30) return { label: 'Fraca', value: 0.5, color: 'warning' };
      if (score >= 20) return { label: 'Muito fraca', value: 0.2, color: 'negative' };
      return { label: '', value: 0, color: '' };
    },
    checkPassword() {
      const obj = {};
      obj.sizeMinimo = this.form?.password.length >= 8;
      obj.sizeMaximo = this.form?.password.length <= 20;
      obj.minusculo = !!this.form?.password.match(/[a-z]/);
      obj.maiusculo = !!this.form?.password.match(/[A-Z]/);
      obj.numero = !!this.form?.password.match(/[0-9]/);
      obj.caracter = !!this.form?.password.match(/[^a-zA-Z0-9]/);
      return obj;
    },
    termsName() {
      if (this.$i18n.locale == 'pt-br') return 'TermsPt';
      if (this.$i18n.locale == 'en') return 'TermsEn';
      return 'TermsPt';
    }
  },
  methods: {
    onScroll(info) {
      if (info.verticalPercentage >= 0.9) this.checkButton = true;
      else this.checkButton = false;
    },
    verifyMethod() {
      this.checkCaptcha = true;
    }
  }
};
</script>

<style></style>
