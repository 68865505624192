import { render, staticRenderFns } from "./TermsEn.vue?vue&type=template&id=322d8f36"
import script from "./TermsEn.vue?vue&type=script&lang=js"
export * from "./TermsEn.vue?vue&type=script&lang=js"
import style0 from "./TermsEn.vue?vue&type=style&index=0&id=322d8f36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports