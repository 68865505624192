<template>
  <div>
    <div class="row justify-center items-center" v-if="successMessage && !openPage">
      <q-card
        class="register-account"
        :bordered="!$q.platform.is.mobile"
        flat
        :class="$q.platform.is.mobile ? 'q-pa-sm  transparent' : 'q-pa-md'"
        :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : 'width: 512px'"
      >
        <q-card-section class="text-center q-pt-md q-pb-none">
          <q-img
            :src="require('@/assets/images/logo_color.png')"
            spinner-color="white"
            width="168px"
            spinner-size="82px"
          />
        </q-card-section>

        <q-card-section class="text-center">
          <h4>Obrigado</h4>
        </q-card-section>

        <q-card-section class="text-center"> Sua conta foi confirmada com sucesso. </q-card-section>

        <q-card-section>
          <div class="row justify-between">
            <q-btn
              unelevated
              color="primary"
              no-caps
              class="full-width q-mb-md q-py-sm text-body1"
              :label="$t('login')"
              to="/auth"
            />
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div class="row justify-center items-center" v-if="!successMessage && !openPage">
      <q-card
        class="register-account"
        :bordered="!$q.platform.is.mobile"
        flat
        :class="$q.platform.is.mobile ? 'q-pa-sm  transparent' : 'q-pa-md'"
        :style="$q.platform.is.mobile ? `width: ${$q.screen.width - 10}px` : 'width: 512px'"
      >
        <q-card-section class="text-center q-pt-md q-pb-none">
          <q-img
            :src="require('@/assets/images/logo_color.png')"
            spinner-color="white"
            width="168px"
            spinner-size="82px"
          />
        </q-card-section>

        <q-card-section class="text-center"> O token informado está expirado ou inválido. </q-card-section>

        <q-card-section>
          <div class="row justify-between">
            <q-btn unelevated flat no-caps color="primary" :label="$t('login')" to="/auth" />

            <q-btn no-caps unelevated flat to="/auth/forgot_password" :label="$t('recover_pass')" />
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  name: 'ActiveAccount',
  mixins: [AuthService],
  props: {
    hash: {
      type: String,
      require: false,
      default: null
    }
  },
  data() {
    return {
      openPage: true,
      successMessage: false
    };
  },
  methods: {
    async activeAccount() {
      if (this.hash === null) {
        this.successMessage = false;

        return false;
      }

      this.onLoading(true);
      try {
        const { status } = await this.activeAccountHash(this.hash);
        if (status === 200) {
          this.successMessage = true;
          this.openPage = false;
        }
      } catch (error) {
        this.successMessage = false;
        this.openPage = false;
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.activeAccount();
  }
};
</script>

<style scoped></style>
