<template>
  <q-card
    :class="$q.platform.is.mobile ? 'q-pa-sm transparent' : 'q-pa-md'"
    flat
    :bordered="!$q.platform.is.mobile"
    :style="$q.platform.is.mobile ? `width:${$q.screen.width}px` : 'width:420px'"
  >
    <q-form @submit="forgetPassword(form)">
      <q-card-section class="text-center q-pt-md q-pb-none">
        <q-img
          :src="require('@/assets/images/logo_color.png')"
          spinner-color="white"
          width="168px"
          spinner-size="82px"
        />
      </q-card-section>
      <q-card-section class="q-gutter-sm q-pb-none">
        <q-input
          v-model="form.email"
          outlined
          type="text"
          label="Email"
          lazy-rules
          :rules="[isRequired, isEmail]"
          @keyup.enter="submit"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-email-outline" color="primary" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section>
        <div class="row justify-between">
          <q-btn no-caps unelevated outline class="q-py-sm q-px-lg" color="primary" to="/auth" :label="$t('cancel')" />
          <q-btn unelevated color="primary" no-caps class="q-py-sm q-px-lg" :label="$t('send')" type="submit" />
        </div>
      </q-card-section>
    </q-form>
  </q-card>
</template>

<script>
import AccountMixin from '../../mixins/AccountMixin';

export default {
  name: 'ForgotPassword',
  mixins: [AccountMixin],
  data() {
    return {
      form: {
        email: null
      }
    };
  }
};
</script>

<style></style>
