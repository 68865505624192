export default {
  methods: {
    authLogin(loginForm) {
      return this.$http.post('/v2/login', loginForm);
    },

    async activeAccountHash(hash) {
      return await this.$http.get(`/activate/${hash}`);
    },

    async userByAdvertiser(advertiserId) {
      return await this.$http.get(`/user/user-by-advertiser/${advertiserId}`);
    }
  },

  authValidate() {
    return this.$http.get(`/v2/auth`);
  }
};
