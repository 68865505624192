<template>
  <div>
    <h1 class="c62">
      <span class="c2">CONTRATO DE LOCA&Ccedil;&Atilde;O DE ESPA&Ccedil;O VIRTUAL</span>
    </h1>
    <p class="c17"><span class="c2"></span></p>
    <p class="c29"><span class="c59"></span></p>
    <p class="c15">
      <span class="c0">De um lado, </span><span class="c2">HI-M&Iacute;DIA INTERNET LTDA, </span
      ><span class="c0"
        >inscrita no CNPJ sob o n&ordm;.07.456.778/0001-59, com sede na cidade e no estado do Rio de Janeiro, na Rua
        Humaita, 275 - 6º andar/7º andar, bairro Humaita, CEP: 22.261-005 (&ldquo;</span
      ><span class="c2">CONTRATADA</span
      ><span class="c0"
        >&rdquo;), e, de outro lado, todas as pessoas f&iacute;sicas ou jur&iacute;dicas, maiores e capazes nos termos
        da lei, que tiverem seu cadastro aprovado para ingressarem na </span
      ><span class="c2">REDE AFILIO </span><span class="c0">(&ldquo;</span><span class="c2">AFILIADO</span
      ><span class="c0"
        >&rdquo;), t&ecirc;m entre si justo e contratado o presente Contrato de Loca&ccedil;&atilde;o de Espa&ccedil;o
        Virtual (&ldquo;</span
      ><span class="c2">Contrato</span
      ><span class="c0">&rdquo;), que apresenta termos e condi&ccedil;&otilde;es gerais estabelecidas entre a </span
      ><span class="c2">CONTRATADA </span><span class="c0">e o </span><span class="c2">AFILIADO </span
      ><span class="c0">quanto ao cadastro e ao ingresso na </span><span class="c2">REDE AFILIO</span
      ><span class="c0">.</span>
    </p>
    <p class="c29"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0 start" start="1">
      <li class="c24 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Condi&ccedil;&otilde;es Gerais</span>
        </h1>
      </li>
    </ol>
    <p class="c3"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start" start="1">
      <li class="c15 c27">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >t&ecirc;m por atividade a loca&ccedil;&atilde;o de espa&ccedil;os para veicula&ccedil;&atilde;o de
          publicidade (&ldquo;</span
        ><span class="c2">Espa&ccedil;o Publicit&aacute;rio</span
        ><span class="c0">&rdquo;) em espa&ccedil;os virtuais como </span><span class="c13">sites</span
        ><span class="c0">, </span><span class="c13">homepages</span><span class="c0">, </span
        ><span class="c13">websites</span><span class="c0">, </span><span class="c13">e-mails </span
        ><span class="c0">(&ldquo;</span><span class="c2">Espa&ccedil;o Virtual</span
        ><span class="c0">&rdquo;), que sejam de propriedade do </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >ou que a eles tenham sido cedidos os direitos de uso, e a subloca&ccedil;&atilde;o desses Espa&ccedil;os
          Publicit&aacute;rios a terceiros (&ldquo;</span
        ><span class="c2">Anunciantes</span
        ><span class="c0"
          >&rdquo;) que tenham interesse em neles anunciar seus produtos e servi&ccedil;os via &lsquo;</span
        ><span class="c13">marketing </span><span class="c0">de afilia&ccedil;&atilde;o&rsquo; (&ldquo;</span
        ><span class="c2">Publicidade</span><span class="c0">&rdquo;).</span>
      </li>
    </ol>
    <p class="c22"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start" start="1">
      <li class="c18">
        <span class="c0"
          >As atividades mencionadas no item 1.1 e as rela&ccedil;&otilde;es jur&iacute;dicas e comerciais criadas entre
          a </span
        ><span class="c2">CONTRATADA </span><span class="c0">e os </span><span class="c2">AFILIADOS </span
        ><span class="c0">s&atilde;o desenvolvidas em uma plataforma denominada &ldquo;</span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >&rdquo;, que, para os fins desse Contrato, deve ser definida como &ldquo;ferramenta que disponibiliza
          oportunidade de loca&ccedil;&atilde;o aos </span
        ><span class="c2">AFILIADOS</span
        ><span class="c0">, monitorando todas as suas transa&ccedil;&otilde;es em tempo real&rdquo;.</span>
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="2">
      <li class="c19">
        <span class="c0">Para se tornar um </span><span class="c2">AFILIADO</span
        ><span class="c0">, &eacute; preciso solicitar o ingresso na </span><span class="c2">REDE AFILIO </span
        ><span class="c0">atrav&eacute;s do preenchimento e envio a </span><span class="c2">CONTRATADA </span
        ><span class="c0">do formul&aacute;rio de cadastro dispon&iacute;vel em seu </span><span class="c13">site</span
        ><span class="c0">. As solicita&ccedil;&otilde;es de ingresso na </span><span class="c2">REDE AFILIO </span
        ><span class="c0">ser&atilde;o avaliadas e confirmadas pela </span><span class="c2">CONTRATADA </span
        ><span class="c0">em at&eacute; 3 (tr&ecirc;s) dias &uacute;teis, contados da data do envio do cadastro.</span>
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="2">
      <li class="c27 c28">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >reserva-se o direito de recusar qualquer solicita&ccedil;&atilde;o de cadastro e de cancelar qualquer
          cadastro previamente aceito, sem que estejam obrigadas a comunicarem ou a exporem as raz&otilde;es de sua
          decis&atilde;o, e sem que isso gere &agrave; </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >qualquer dever ou obriga&ccedil;&atilde;o de indeniza&ccedil;&atilde;o ou de ressarcimento de qualquer
          esp&eacute;cie em face de qualquer pessoa.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="3">
      <li class="c27 c35">
        <span class="c0">A recusa de cadastro pela </span><span class="c2">CONTRATADA </span
        ><span class="c0">implica no impedimento do solicitante do cadastro em ingressar na </span
        ><span class="c2">REDE AFILIO </span
        ><span class="c0">e, consequentemente, de adquirir a condi&ccedil;&atilde;o de </span
        ><span class="c2">AFILIADO</span
        ><span class="c0"
          >, o que tamb&eacute;m implica no impedimento do solicitante em pleitear novo cadastro utilizando outros
          Espa&ccedil;os Virtuais de sua propriedade ou de terceiros que lhe tenham outorgado direitos para tal.</span
        >
      </li>
    </ol>
    <p class="c17"><span class="c0"></span></p>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="3">
      <li class="c8">
        <span class="c0">Ap&oacute;s a aprova&ccedil;&atilde;o do ingresso do </span><span class="c2">AFILIADO </span
        ><span class="c0">na </span><span class="c2">REDE AFILIO</span><span class="c0">, a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >ainda dever&aacute; aprovar, a seu crit&eacute;rio, o Espa&ccedil;o Publicit&aacute;rio do </span
        ><span class="c2">AFILIADO </span><span class="c0">para a veicula&ccedil;&atilde;o de cada Publicidade.</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="4">
      <li class="c27 c47">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >&eacute; o provedor e o &uacute;nico e exclusivo respons&aacute;vel pelo Espa&ccedil;o Virtual com
          aptid&atilde;o para veicular Publicidades cadastrado por ele na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">, e locado para a </span><span class="c2">CONTRATADA</span
        ><span class="c0">, nos termos desse Contrato.</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="2">
      <li class="c24 li-bullet-0">
        <h1 style="display: inline"><span class="c2">Objeto</span></h1>
      </li>
    </ol>
    <p class="c22"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start" start="1">
      <li class="c19">
        <span class="c0"
          >O objeto do presente Contrato consiste na loca&ccedil;&atilde;o do Espa&ccedil;o Publicit&aacute;rio pelo </span
        ><span class="c2">AFILIADO </span><span class="c0">&agrave; </span><span class="c2">CONTRATADA </span
        ><span class="c0">e a autoriza&ccedil;&atilde;o do </span><span class="c2">AFILIADO </span
        ><span class="c0">&agrave; </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >para que esta possa sublocar, ceder ou emprestar o Espa&ccedil;o Publicit&aacute;rio aos </span
        ><span class="c2">ANUNCIANTES</span
        ><span class="c0"
          >, com a finalidade de veicula&ccedil;&atilde;o de Publicidade, mediante o pagamento de
          remunera&ccedil;&atilde;o na forma da Cl&aacute;usula 3 deste Contrato.</span
        >
      </li>
      <li class="c19">
        <span class="c0"
          >Para os fins deste Contrato, o Espa&ccedil;o Publicit&aacute;rio ser&aacute; considerado como sublocado
          (&ldquo;</span
        ><span class="c2">Espa&ccedil;o Sublocado</span><span class="c0">&rdquo;)</span>
      </li>
    </ol>
    <p class="c30">
      <span class="c25">quando nele for veiculada Publicidade de </span><span class="c25 c61">ANUNCIANTES</span
      ><span class="c25">, por meio da </span><span class="c25 c61">REDE AFILIO</span><span class="c0">.</span>
    </p>
    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="3">
      <li class="c24 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Remunera&ccedil;&atilde;o e Forma de Pagamento</span>
        </h1>
      </li>
    </ol>
    <p class="c3"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c55 c27">
        <span class="c0"
          >O valor da remunera&ccedil;&atilde;o pela loca&ccedil;&atilde;o do Espa&ccedil;o Publicit&aacute;rio
          (&ldquo;</span
        ><span class="c2">Remunera&ccedil;&atilde;o</span
        ><span class="c0">&rdquo;) ser&aacute; determinado a partir do desempenho da Publicidade (&ldquo;</span
        ><span class="c2">Desempenho da Publicidade</span
        ><span class="c0">&rdquo;) e de acordo com o modelo de publicidade estabelecido na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c29"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c27 c49">
        <span class="c0"
          >Entende-se por Desempenho da Publicidade o atingimento de seu objetivo, que pode ser caracterizado por
          &lsquo;cliques&rsquo; na publicidade, por visualiza&ccedil;&atilde;o, por registro, por
          transa&ccedil;&otilde;es comerciais ou outras a&ccedil;&otilde;es semelhantes, que representam a
          intera&ccedil;&atilde;o de um usu&aacute;rio da internet com a Publicidade.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="5">
      <li class="c27 c48">
        <span class="c0">As a&ccedil;&otilde;es mencionadas encontram-se definidas e detalhadas na </span
        ><span class="c2">REDE AFILIO </span
        ><span class="c0">e podem ser diferentes para cada tipo de Publicidade a ser veiculada.</span>
      </li>
    </ol>
    <p class="c3"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="6">
      <li class="c28 c27">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >monitora as a&ccedil;&otilde;es e disponibilizam as informa&ccedil;&otilde;es obtidas ao </span
        ><span class="c2">AFILIADO</span><span class="c0">, por meio de relat&oacute;rio dispon&iacute;vel na </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, para fins de apura&ccedil;&atilde;o da Remunera&ccedil;&atilde;o, sendo que o &uacute;nico par&acirc;metro
          v&aacute;lido para a apura&ccedil;&atilde;o do valor do aluguel s&atilde;o as informa&ccedil;&otilde;es de
          monitoramento auferidas e disponibilizadas pela </span
        ><span class="c2">CONTRATADA</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c0"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="7">
      <li class="c18">
        <span class="c0">Em caso de suspeita de fraudes pela </span><span class="c2">CONTRATADA </span
        ><span class="c0">ou pelos </span><span class="c2">ANUNCIANTES </span><span class="c0">por parte do </span
        ><span class="c2">AFILIADO</span
        ><span class="c0"
          >, sejam elas aquelas mencionadas na cl&aacute;usula 6 abaixo, ou qualquer outra visando ao recebimento de
          valores maiores pela loca&ccedil;&atilde;o realizada, ou para qualquer outra finalidade e, ainda que a fraude
          n&atilde;o seja perpetrada pelo </span
        ><span class="c2">AFILIADO</span
        ><span class="c0"
          >, mas facilitada por fragilidades ou inadequa&ccedil;&otilde;es de seu Espa&ccedil;o Virtual, restar&aacute;
          suspenso todo e qualquer pagamento que seja ou que venha a ser devido, sem preju&iacute;zo da
          responsabiliza&ccedil;&atilde;o do </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >pelo pagamento de perdas e danos eventualmente decorrentes do mencionado questionamento.</span
        >
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="8">
      <li class="c8">
        <span class="c25 c39"
          >Apenas ser&atilde;o considerados devidos os valores da Remunera&ccedil;&atilde;o que s&atilde;o
          correspondentes ao desempenho da Publicidade efetivamente realizada. Qualquer caso que gere um n&atilde;o
          recebimento por parte da </span
        ><span class="c5">CONTRATADA </span
        ><span class="c39 c25">em rela&ccedil;&atilde;o aos valores devidos pelos </span
        ><span class="c5">ANUNCIANTES</span
        ><span class="c39 c25">, implicar&aacute; que n&atilde;o seja devido qualquer valor ao </span
        ><span class="c5">AFILIADO</span><span class="c39 c25">.</span>
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="9">
      <li class="c47 c27">
        <span class="c0">Para o recebimento da remunera&ccedil;&atilde;o pela loca&ccedil;&atilde;o, o </span
        ><span class="c2">AFILIADO </span><span class="c0">dever&aacute; emitir e enviar &agrave; </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0">, a fatura com o valor do saldo a ele liberado, na forma e nos prazos constantes na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c17"><span class="c0"></span></p>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c28 c27">
        <span class="c39 c25">O </span><span class="c5">AFILIADO </span
        ><span class="c39 c25"
          >tem o prazo de 12 (doze) meses contados do m&ecirc;s de veicula&ccedil;&atilde;o da Publicidade para
          encaminhar &agrave; respectiva </span
        ><span class="c5">CONTRATADA </span
        ><span class="c39 c25">a mencionada fatura. Ap&oacute;s decorrido este prazo, o </span
        ><span class="c5">AFILIADO </span
        ><span class="c39 c25"
          >perde o direito &agrave; cobran&ccedil;a do valor das campanhas daquele per&iacute;odo.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="10">
      <li class="c10" id="h.gjdgxs">
        <span class="c0">A Remunera&ccedil;&atilde;o ser&aacute; apurada pela </span><span class="c2">CONTRATADA </span
        ><span class="c0">junto aos </span><span class="c2">ANUNCIANTES </span
        ><span class="c0"
          >em at&eacute; 21 (vinte e um) dias ap&oacute;s o encerramento do m&ecirc;s, que, para os fins desse Contrato
          &eacute; considerado como o &uacute;ltimo dia &uacute;til do referido m&ecirc;s.</span
        >
      </li>
    </ol>
    <p class="c3"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c18">
        <span class="c0">N&atilde;o obstante o prazo estabelecido no item </span
        ><span class="c0"><a class="c14" href="#h.gjdgxs">3.4</a></span
        ><span class="c0">, poder&atilde;o ocorrer atrasos por parte da </span><span class="c2">CONTRATADA </span
        ><span class="c0">na apura&ccedil;&atilde;o e na disponibiliza&ccedil;&atilde;o dos saldos de cada </span
        ><span class="c2">AFILIADO</span
        ><span class="c0">, sem que isso caracterize qualquer infra&ccedil;&atilde;o contratual por parte da </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">e sem que lhe sejam impostos quaisquer &ocirc;nus ou penalidades.</span>
      </li>
    </ol>
    <ol class="c1 lst-kix_list_1-1" start="11">
      <li class="c27 c36" id="h.30j0zll">
        <span class="c0"
          >O pagamento da remunera&ccedil;&atilde;o pela loca&ccedil;&atilde;o do Espa&ccedil;o Publicit&aacute;rio
          ser&aacute; efetuado mensalmente, mas efetivamente ocorrer&aacute; apenas quando o cr&eacute;dito do </span
        ><span class="c2">AFILIADO </span><span class="c0">constante na </span><span class="c2">REDE AFILIO </span
        ><span class="c0">for igual ou superior a R$ 500,00 (quinhentos reais).</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c18">
        <span class="c0">Caso o valor m&iacute;nimo mencionado no item </span
        ><span class="c0"><a class="c14" href="#h.30j0zll">3.5 </a></span
        ><span class="c0">n&atilde;o seja atingido pelo </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >no m&ecirc;s em refer&ecirc;ncia, ou caso n&atilde;o seja feito o pagamento do m&ecirc;s em refer&ecirc;ncia
          em raz&atilde;o do n&atilde;o envio da fatura (item 3.3) pelo </span
        ><span class="c2">AFILIADO</span><span class="c0">, mesmo atingido o valor m&iacute;nimo, o saldo do </span
        ><span class="c2">AFILIADO </span><span class="c0">ser&aacute; mantido como cr&eacute;dito na </span
        ><span class="c2">REDE AFILIO </span><span class="c0">para o pr&oacute;ximo m&ecirc;s.</span>
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="12">
      <li class="c19">
        <span class="c0"
          >Ap&oacute;s o encerramento de cada m&ecirc;s, feita a apura&ccedil;&atilde;o do Desempenho da Publicidade, e
          configurado o valor m&iacute;nimo, ser&aacute; feito o pagamento da Remunera&ccedil;&atilde;o ao </span
        ><span class="c2">AFILIADO </span><span class="c0">pela </span><span class="c2">CONTRATADA</span
        ><span class="c0"
          >, sendo certo que do valor pago ser&atilde;o deduzidos os tributos incidentes nos termos da lei, bem como os
          custos administrativos elencados na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c29"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="13">
      <li class="c27 c55">
        <span class="c0">O pagamento da Remunera&ccedil;&atilde;o ser&aacute; realizado pela </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >mediante transfer&ecirc;ncia banc&aacute;ria direta para a conta corrente indicada previamente pelo </span
        ><span class="c2">AFILIADO </span><span class="c0">na </span><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, desde que a referida conta seja brasileira, sendo que ser&atilde;o descontadas do valor da
          Remunera&ccedil;&atilde;o as taxas banc&aacute;rias eventualmente cobradas na opera&ccedil;&atilde;o de
          transfer&ecirc;ncia banc&aacute;ria.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c18">
        <span class="c0">Caso a conta banc&aacute;ria indicada pelo </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >seja estrangeira, o pagamento da remunera&ccedil;&atilde;o ser&aacute; efetuado mediante transfer&ecirc;ncia
          de c&acirc;mbio junto ao Banco Central do Brasil, sendo de responsabilidade exclusiva do </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >o pagamento de todo e qualquer custo decorrente da mencionada opera&ccedil;&atilde;o, inclusive os tributos
          que porventura venham a incidir sobre a opera&ccedil;&atilde;o.</span
        >
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="12">
      <li class="c28 c27">
        <span class="c0">Os valores da remunera&ccedil;&atilde;o ser&atilde;o pagos pela </span
        ><span class="c2">CONTRATADA </span><span class="c0">em moeda corrente brasileira (Real), sendo o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >respons&aacute;vel por eventuais custos advindos da varia&ccedil;&atilde;o cambial e da convers&atilde;o da
          moeda. A data da convers&atilde;o ser&aacute; considerada como a data de fechamento de c&acirc;mbio junto ao
          Banco Central do Brasil e as taxas ser&atilde;o as determinadas pelo Banco Central do Brasil.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="4">
      <li class="c63 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Responsabilidades do Afiliado</span>
        </h1>
      </li>
    </ol>
    <p class="c17"><span class="c45"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c10">
        <span class="c0">Durante a vig&ecirc;ncia deste Contrato, o </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >fica vedado de realizar novas contrata&ccedil;&otilde;es ou negocia&ccedil;&otilde;es, diretamente com os
          Anunciantes da </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0">, que envolvam o uso de seu Espa&ccedil;o Publicit&aacute;rio.</span>
      </li>
    </ol>
    <p class="c3"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c18">
        <span class="c0">Descumprida a obriga&ccedil;&atilde;o mencionada no item 4.1 pelo </span
        ><span class="c2">AFILIADO</span
        ><span class="c0"
          >, este incorrer&aacute; no pagamento de multa equivalente a 100% (cem por cento) do valor da
          Remunera&ccedil;&atilde;o apurada nos &uacute;ltimos 6 (seis) meses ou R$ 150.000,00 (cento e cinquenta mil
          reais), o maior montante dentre esses dois, a partir do m&ecirc;s em que for constatado o descumprimento, sem
          preju&iacute;zo da repara&ccedil;&atilde;o de todas as custas e despesas administrativas caso a multa seja
          demandada administrativa ou judicialmente.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="14">
      <li class="c18">
        <span class="c0">Verificada a hip&oacute;tese da multa, fica autorizado que a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">desconte o valor total ou parcial da multa aplicada dos pagamentos que o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0">tenha direito a receber, ou dos cr&eacute;ditos que tenha na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="15">
      <li class="c31 c27">
        <span class="c0">O registro na </span><span class="c2">REDE AFILIO </span
        ><span class="c0"
          >&eacute; pessoal e intransfer&iacute;vel, sendo que nenhuma outra pessoa pode ter acesso aos dados do </span
        ><span class="c2">AFILIADO</span><span class="c0">, que dever&aacute; comunicar imediatamente &agrave; </span
        ><span class="c2">CONTRATADA</span><span class="c0">, atrav&eacute;s do e-mail </span
        ><span class="c0"><a class="c14" href="mailto:contato@afil.io">contato@afil.io </a></span
        ><span class="c0">caso tenha conhecimento de qualquer acesso por terceiros aos seus dados.</span>
      </li>
      <li class="c27 c51">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >&eacute; respons&aacute;vel por toda informa&ccedil;&atilde;o inserida por ele em seu cadastro na </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, responsabilizando-se pela veracidade das mencionadas informa&ccedil;&otilde;es e obrigando-se a
          mant&ecirc;-las sempre atualizadas.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="17">
      <li class="c27 c31">
        <span class="c0">Caso o </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >aliene ou vincule seus direitos sobre o Espa&ccedil;o Publicit&aacute;rio a terceiros alheios &agrave; este
          Contrato ou &agrave;s atividades desenvolvidas na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">, o </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >obrigar-se-&aacute; a dar ci&ecirc;ncia ao terceiro dos termos do presente Contrato, restando
          respons&aacute;vel por assegurar que ele os cumpra integralmente, bem como por eventuais danos por ele
          causados.</span
        >
      </li>
    </ol>
    <p class="c21"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="18">
      <li class="c15 c27">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >&eacute; o &uacute;nico e exclusivo respons&aacute;vel pelo conte&uacute;do de seu Espa&ccedil;o Virtual,
          sendo que, caso usu&aacute;rios ou quaisquer outras pessoas aju&iacute;zem quaisquer tipos de
          a&ccedil;&otilde;es ou reclama&ccedil;&otilde;es judiciais referentes ao conte&uacute;do ou quaisquer outros
          aspectos relacionados ao Espa&ccedil;o Virtual, o </span
        ><span class="c2">AFILIADO </span><span class="c0">estar&aacute; obrigado a eximir judicialmente a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >de quaisquer responsabilidades, obriga&ccedil;&atilde;o esta que se estende aos diretores, gerentes,
          empregados, agentes, colaboradores e prepostos da </span
        ><span class="c2">CONTRATADA</span><span class="c0">, bem como o </span><span class="c2">AFILIADO </span
        ><span class="c0">estar&aacute; obrigado a reparar a </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >quanto aos custos que incorrer em raz&atilde;o das a&ccedil;&otilde;es ou reclama&ccedil;&otilde;es
          mencionadas, sem preju&iacute;zo da repara&ccedil;&atilde;o por eventuais perdas e danos.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="19">
      <li class="c15 c27">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >&eacute; respons&aacute;vel ainda por providenciar o descadastramento, da sua base de dados, dos
          Usu&aacute;rios ou terceiros destinat&aacute;rios finais da Publicidade, em at&eacute; 24hrs (vinte e quatro
          horas) ap&oacute;s receber uma solicita&ccedil;&atilde;o de </span
        ><span class="c13">opt-out, </span
        ><span class="c0"
          >seja essa solicita&ccedil;&atilde;o oriunda do pr&oacute;prio Usu&aacute;rio ou terceiro, como proveniente da </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0"
          >, sob pena de, n&atilde;o o fazendo no prazo ajustado, incorrer no pagamento da multa prevista no item 4.1.1
          supra, ressalvando-se ainda que, caso a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">seja demandada administrativa ou judicialmente por fatos atribu&iacute;veis ao </span
        ><span class="c2">AFILIADO</span
        ><span class="c0"
          >, poder&aacute; denunci&aacute;-lo &agrave; lide, sem preju&iacute;zo do direito de regresso por todos os
          danos e custos decorrentes.</span
        >
      </li>
    </ol>

    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="19">
      <li class="c15 c27">
        <span class="c0"
          >Fica estabelecido que o <span class="c2">AFILIADO</span> n&atilde;o contratar&aacute;, persuadir&aacute;,
          aliciar&aacute; ou tentar&aacute; atrair qualquer funcion&aacute;rio, empregado, diretor e/ou prepostos da
          <span class="c2">CONTRATADA</span>, bem como qualquer cliente a deixar de realizar neg&oacute;cios com a
          <span class="c2">CONTRATADA</span>, ainda que para outras fun&ccedil;&otilde;es, durante ou ap&oacute;s a
          vig&ecirc;ncia do presente Contrato, pelo per&iacute;odo de 12 (doze) meses consecutivos.
        </span>
      </li>
    </ol>

    <p class="c17"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c23">
        <span class="c0"
          >Descumprida a obriga&ccedil;&atilde;o mencionada no item 4.7 pelo <span class="c2">AFILIADO</span>, este
          incorrer&aacute; no pagamento de multa equivalente a 100% (cem por cento) do valor da
          Remunera&ccedil;&atilde;o apurada nos &uacute;ltimos 3 (tr&ecirc;s) meses para cada pessoa da
          <span class="c2">CONTRATADA</span> aliciada, 6 (seis) meses para cada pessoa efetivamente contratada pelo
          <span class="c2">AFILIADO</span> ou R$ 60.000,00 (sessenta mil reais), o maior montante dentre esses
          tr&ecirc;s, a partir do m&ecirc;s em que for constatado o descumprimento, sem preju&iacute;zo da
          repara&ccedil;&atilde;o de todas as custas e despesas administrativas caso a multa seja demandada
          administrativa ou judicialmente.
        </span>
      </li>
    </ol>

    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="5">
      <li class="c9 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Confidencialidade das Informa&ccedil;&otilde;es</span>
        </h1>
      </li>
    </ol>
    <p class="c16"><span class="c52"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c8">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >&eacute; respons&aacute;vel por proteger a privacidade das informa&ccedil;&otilde;es ofertadas pelo </span
        ><span class="c2">AFILIADO </span><span class="c0">(&ldquo;</span
        ><span class="c2">Informa&ccedil;&otilde;es</span><span class="c0">&rdquo;) na </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, exceto quanto as Informa&ccedil;&otilde;es forem solicitadas por ordens judiciais, requerimentos oficiais
          ou exig&ecirc;ncias legais.</span
        >
      </li>
    </ol>
    <p class="c17"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c23">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >se responsabiliza, em nenhuma hip&oacute;tese, pela revela&ccedil;&atilde;o de Informa&ccedil;&atilde;o caso
          Usu&aacute;rios ou terceiros eventualmente as interceptem ou acessem ilegalmente.</span
        >
      </li>
    </ol>
    <p class="c17"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="21">
      <li class="c31 c27">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >compromete-se a manter em estrita confidencialidade todas as informa&ccedil;&otilde;es a que tiver acesso
          pela sua condi&ccedil;&atilde;o de </span
        ><span class="c2">AFILIADO</span
        ><span class="c0">, incluindo os detalhes a respeito das ferramentas de uso da </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, das estruturas de promo&ccedil;&otilde;es, dentre outras informa&ccedil;&otilde;es, sendo que esta
          obriga&ccedil;&atilde;o se estender&aacute; pelo per&iacute;odo de 2 (dois) anos ap&oacute;s o fim da sua
          rela&ccedil;&atilde;o de </span
        ><span class="c2">AFILIADO</span
        ><span class="c0">, sob pena de ser obrigado a indenizar por perdas e danos.</span>
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="6">
      <li class="c9 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Das Restri&ccedil;&otilde;es e Fraudes</span>
        </h1>
      </li>
    </ol>
    <p class="c21"><span class="c52"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c19">
        <span class="c0">N&atilde;o ser&atilde;o toleradas pela </span><span class="c2">CONTRATADA </span
        ><span class="c0">as seguintes pr&aacute;ticas:</span>
      </li>
    </ol>
    <p class="c22"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="16">
      <li class="c27 c37">
        <span class="c0"
          >Utiliza&ccedil;&atilde;o de sistemas nos quais o cadastro seja involunt&aacute;rio e/ ou autom&aacute;tico
          nos sites dos anunciantes da </span
        ><span class="c2">CONTRATADA</span><span class="c0">;</span>
      </li>
      <li class="c27 c65">
        <span class="c0"
          >Oferta de dinheiro ou qualquer tipo de artigos, produtos, vantagens, brindes ou servi&ccedil;os e/ou realizar
          alguma promo&ccedil;&atilde;o com o fim de gerar vendas, a&ccedil;&otilde;es ou cadastros nos sites dos
          anunciantes da </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0">, sem a devida pr&eacute;via autoriza&ccedil;&atilde;o;</span>
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="18">
      <li class="c28 c27">
        <span class="c0"
          >Envio indiscriminado de e-mails com o intuito de promover algum produto cadastrado em um dos sites dos
          anunciantes da </span
        ><span class="c2">CONTRATADA </span><span class="c0">ou de gerar a entrada nos sites dos anunciantes da </span
        ><span class="c2">CONTRATADA </span><span class="c0">ou ainda o envio massificado de e-mails </span
        ><span class="c13">spam </span><span class="c0">sob qualquer condi&ccedil;&atilde;o;</span>
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="19">
      <li class="c18">
        <span class="c0"
          >Envio de correspond&ecirc;ncia, eletr&ocirc;nica ou n&atilde;o, que induza o destinat&aacute;rio em erro ou
          confus&atilde;o acerca do remetente do e-mail, levando-o a crer que este seria a pr&oacute;pria </span
        ><span class="c2">CONTRATADA </span><span class="c0">ou um dos Anunciantes, ou utilizando as marcas da </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >e dos Anunciantes de sua plataforma de afilia&ccedil;&atilde;o, sem autoriza&ccedil;&atilde;o
          pr&eacute;via;</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="20">
      <li class="c27 c44">
        <span class="c0"
          >Utiliza&ccedil;&atilde;o de cadastro de terceiros para fraudar o sistema e, de alguma forma, quebrar as
          restri&ccedil;&otilde;es aqui descritas ou em Termos e Condi&ccedil;&otilde;es Gerais de outras
          pol&iacute;ticas da </span
        ><span class="c2">CONTRATADA</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c22"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="21">
      <li class="c18">
        <span class="c0">Valer-se de qualquer mecanismo que vise obter remunera&ccedil;&atilde;o da </span
        ><span class="c2">REDE AFILIO </span
        ><span class="c0"
          >sem a devida promo&ccedil;&atilde;o dos seus Anunciantes, tal como, mas sem limitar-se a,
          aposi&ccedil;&atilde;o de </span
        ><span class="c13">cookies </span
        ><span class="c0">indiscriminadamente, sem que o internauta que recebe o referido </span
        ><span class="c13">cookie </span
        ><span class="c0">haja clicado em qualquer pe&ccedil;a publicit&aacute;ria divulgada por meio da </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >; criar sites com dom&iacute;nios que guardem semelhan&ccedil;a fon&eacute;tica ou sem&acirc;ntica com a </span
        ><span class="c2">CONTRATADA </span><span class="c0">e os Anunciantes da </span
        ><span class="c2">CONTRATADA</span><span class="c0">;</span>
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-2" start="22">
      <li class="c28 c27">
        <span class="c0"
          >Contratar com quaisquer sites de busca a compra de &lsquo;links patrocinados&rsquo; como forma de
          gera&ccedil;&atilde;o de tr&aacute;fego para os sites dos </span
        ><span class="c2">ANUNCIANTES </span><span class="c0">da </span><span class="c2">CONTRATADA</span
        ><span class="c0">, exceto quando a </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >houver expressamente autorizado tal contrata&ccedil;&atilde;o, por meio de instrumento escrito que
          indicar&aacute; os limites para tal contrata&ccedil;&atilde;o.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="23">
      <li class="c19">
        <span class="c0">A pr&aacute;tica de qualquer dessas infra&ccedil;&otilde;es implicar&aacute; ao </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >n&atilde;o apenas a responsabilidade por todas as consequ&ecirc;ncias que o ato possa causar &agrave; </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >ou a terceiros, bem como implicar&aacute; na suspens&atilde;o ou no cancelamento da sua
          condi&ccedil;&atilde;o de </span
        ><span class="c2">AFILIADO</span><span class="c0">, &agrave; crit&eacute;rio da </span
        ><span class="c2">CONTRATADA</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="24">
      <li class="c19">
        <span class="c0">A exist&ecirc;ncia de ind&iacute;cios por parte da </span><span class="c2">CONTRATADA </span
        ><span class="c0">ou de algum anunciante de que o </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >praticou alguma das atividades mencionadas na cl&aacute;usula 6.1, ou ainda alguma outra, que de alguma forma
          possa se configurar como uma tentativa de fraude ao formato de neg&oacute;cio da </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">devidamente descrito por meio deste Contrato, ensejar&aacute; a possibilidade de a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">reterem, por at&eacute; 6 (seis) meses, eventuais pagamentos que tenha a realizar ao </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >na forma das cl&aacute;usulas 3 e 4, ficando desde j&aacute; autorizada a compensa&ccedil;&atilde;o dos
          valores retidos com eventuais preju&iacute;zos que tenham sido causados pelas pr&aacute;ticas inadequadas
          comprovadamente. Uma vez realizada a reten&ccedil;&atilde;o, o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >ser&aacute; comunicado, para que explique a pr&aacute;tica que gerou a evid&ecirc;ncia, sendo que a
          libera&ccedil;&atilde;o dos valores retidos ocorrer&aacute; caso fique devidamente esclarecida a
          quest&atilde;o, a crit&eacute;rio da </span
        ><span class="c2">CONTRATADA</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c0"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="7">
      <li class="c24 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Ferramentas da Contratada</span>
        </h1>
      </li>
    </ol>
    <p class="c22"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c27 c33">
        <span class="c0">Quando notificado de sua aceita&ccedil;&atilde;o e ingresso na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">, o </span><span class="c2">AFILIADO </span
        ><span class="c0">poder&aacute; colocar em seu Espa&ccedil;o Virtual ferramentas e/ou materiais da </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0">, sem limite de quantidade, as quais conter&atilde;o a identifica&ccedil;&atilde;o do </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >e demais par&acirc;metros necess&aacute;rios para direcionar os visitantes de sua p&aacute;gina aos sites dos
          Anunciantes da </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0"
          >, com suas respectivas categorias ou an&uacute;ncios segundo as diferentes fun&ccedil;&otilde;es que cada um
          tenha, bem como, possibilitar o monitoramento necess&aacute;rio para o correto cr&eacute;dito dos pagamentos.
          Os materiais poder&atilde;o ter diversos formatos e est&atilde;o disponibilizados na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
      <li class="c19">
        <span class="c0">Caber&aacute; &agrave; </span><span class="c2">CONTRATADA </span
        ><span class="c0">disponibilizarem </span><span class="c13">banners</span><span class="c0">, </span
        ><span class="c13">links </span><span class="c0">e outras pe&ccedil;as publicit&aacute;rias na </span
        ><span class="c2">REDEAFILIO</span><span class="c0">, para que o</span>
      </li>
    </ol>
    <p class="c40">
      <span class="c2">AFILIADO </span
      ><span class="c0">possa baix&aacute;-los e public&aacute;-los em seu Espa&ccedil;o Virtual.</span>
    </p>
    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="27">
      <li class="c15 c27">
        <span class="c0">Caber&aacute; ao </span><span class="c2">AFILIADO </span
        ><span class="c0">copiar devidamente o c&oacute;digo informado ao lado dos links para </span
        ><span class="c13">download </span><span class="c0">dos </span><span class="c13">banners</span
        ><span class="c0"
          >. Esse c&oacute;digo possibilita a identifica&ccedil;&atilde;o do Espa&ccedil;o Virtual do </span
        ><span class="c2">AFILIADO </span
        ><span class="c0">e a contabiliza&ccedil;&atilde;o das vendas provenientes dele. O </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >tamb&eacute;m &eacute; respons&aacute;vel pelo local escolhido para a publica&ccedil;&atilde;o dos </span
        ><span class="c13">banners </span
        ><span class="c0"
          >e pela devida inser&ccedil;&atilde;o do c&oacute;digo, o que requer conhecimentos b&aacute;sicos de </span
        ><span class="c13">Hyper Text Markup Language </span><span class="c0">(HTML).</span>
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="28">
      <li class="c10">
        <span class="c0">Fica estabelecido que a </span><span class="c2">CONTRATADA </span
        ><span class="c0">utilizar&aacute; de sua </span><span class="c2">PLATAFORMA AFILIO </span
        ><span class="c0"
          >para contabilizar e confirmar os cliques, cadastros, vendas e, ainda, monitorar a atividade do
          Usu&aacute;rio, baseado em </span
        ><span class="c13">cookies</span
        ><span class="c0">. Uma vez que a perman&ecirc;ncia e exist&ecirc;ncia dos </span
        ><span class="c13">cookies </span
        ><span class="c0">no computador dos Usu&aacute;rios dependem unicamente da vontade deles, a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">n&atilde;o se responsabiliza caso os Usu&aacute;rios eliminem ou n&atilde;o aceitem os </span
        ><span class="c13">cookies </span><span class="c0">da </span><span class="c2">CONTRATADA</span
        ><span class="c0">.</span>
      </li>
    </ol>
    <p class="c22"><span class="c50"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="29">
      <li class="c19">
        <span class="c0"
          >Somente ser&aacute; permitida a modifica&ccedil;&atilde;o dos c&oacute;digos HTML fornecidos mediante
          autoriza&ccedil;&atilde;o expressa e por escrito da </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0"
          >. O controle da conta e verifica&ccedil;&atilde;o dos pagamentos ser&aacute; realizado estritamente pela </span
        ><span class="c2">CONTRATADA</span><span class="c0">. A </span><span class="c2">CONTRATADA </span
        ><span class="c0">apenas responsabilizar-se-&atilde;o pelas ferramentas da </span
        ><span class="c2">REDE AFILIO </span><span class="c0">criadas e disponibilizadas diretamente por ela.</span>
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="30">
      <li class="c31 c27">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >declara ter ci&ecirc;ncia de que, nos termos definidos na legisla&ccedil;&atilde;o em vigor, inclusive, a
          depender do aplic&aacute;vel a cada caso, na Lei Geral de Prote&ccedil;&atilde;o de Dados n&ordm; 13.709/18
          (LGPD) ou no Regulamento Geral sobre Prote&ccedil;&atilde;o de Dados n&ordm; 2016/679 (GDPR), seus dados
          ser&atilde;o acessados pela </span
        ><span class="c2">CONTRATADA</span><span class="c0">, em especial, mas n&atilde;o se limitando ao caso de </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >pessoa f&iacute;sica, nos termos do presente Contrato &uacute;nica e exclusivamente para
          execu&ccedil;&atilde;o deste Contrato, nos termos aqui estabelecidos e por meio do sistema de rastreio
          mencionado na cl&aacute;usula</span
        >
      </li>
    </ol>
    <p class="c64">
      <span class="c0">7.4 acima, com o que o </span><span class="c2">AFILIADO </span
      ><span class="c0">desde j&aacute; concorda expressamente. Dessa forma, a </span><span class="c2">CONTRATADA </span
      ><span class="c0">n&atilde;o se responsabiliza pelas informa&ccedil;&otilde;es veiculadas pelos </span
      ><span class="c2">AFILIADOS</span
      ><span class="c0"
        >, se resguardando ao direito de se ressarcir na hip&oacute;tese de eventualmente ser responsabilizada por
        informa&ccedil;&otilde;es irregulares ou incorretas de campanhas veiculadas em canais do </span
      ><span class="c2">AFILIADO </span><span class="c0">ou Redes Sociais em geral.</span>
    </p>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="8">
      <li class="c24 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Limita&ccedil;&atilde;o da Licen&ccedil;a</span>
        </h1>
      </li>
    </ol>
    <p class="c22"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c8">
        <span class="c0">Todos os direitos intelectuais e industriais sobre o </span><span class="c13">site</span
        ><span class="c0">, opera&ccedil;&otilde;es, </span><span class="c13">software</span><span class="c0">, </span
        ><span class="c13">hardware</span><span class="c0">, dom&iacute;nio, logomarcas, emblemas, logotipos, </span
        ><span class="c13">design </span
        ><span class="c0"
          >de p&aacute;ginas e pe&ccedil;as publicit&aacute;rias, estrutura, conte&uacute;dos,
          informa&ccedil;&otilde;es, ferramentas da </span
        ><span class="c2">REDE AFILIO </span><span class="c0">etc. s&atilde;o de propriedade absoluta da </span
        ><span class="c2">CONTRATADA </span><span class="c0">e dos Anunciantes.</span>
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="32">
      <li class="c8">
        <span class="c0">Em nenhum caso se entender&aacute; que o </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >possui qualquer tipo de direito sobre os direitos mencionados no item 8.1, independentemente dos direitos
          conferidos sobre as ferramentas da </span
        ><span class="c2">REDE AFILIO </span><span class="c0">que a </span><span class="c2">CONTRATADA </span
        ><span class="c0">colocar &agrave; disposi&ccedil;&atilde;o do </span><span class="c2">AFILIADO </span
        ><span class="c0">durante a vig&ecirc;ncia deste Contrato.</span>
      </li>
    </ol>
    <p class="c21"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="33">
      <li class="c15 c27">
        <span class="c0">A </span><span class="c2">CONTRATADA </span><span class="c0">somente autoriza ao </span
        ><span class="c2">AFILIADO </span
        ><span class="c0">a fazer uso de sua propriedade intelectual no que diz respeito &agrave;s ferramentas da </span
        ><span class="c2">REDE AFILIO </span
        ><span class="c0"
          >colocadas &agrave; disposi&ccedil;&atilde;o dele para o cumprimento das atividades que derivam do presente
          Contrato. Qualquer outra utiliza&ccedil;&atilde;o de tal propriedade intelectual da </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0"
          >&eacute; terminantemente proibida. Na medida em que se cumpram estas limita&ccedil;&otilde;es, a </span
        ><span class="c2">CONTRATADA </span
        ><span class="c0">outorga uma licen&ccedil;a gratuita, n&atilde;o exclusiva e revog&aacute;vel ao </span
        ><span class="c2">AFILIADO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c26"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="9">
      <li class="c58 li-bullet-0">
        <h1 style="display: inline">
          <span class="c2">Limita&ccedil;&atilde;o de Responsabilidade</span>
        </h1>
      </li>
    </ol>
    <p class="c22"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c19">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >n&atilde;o garante o acesso e o uso continuado ou ininterrupto de seu site, sendo que o sistema pode
          eventualmente n&atilde;o estar dispon&iacute;vel devido a dificuldades t&eacute;cnicas ou falhas de </span
        ><span class="c13">internet </span><span class="c0">nos </span><span class="c13">links </span
        ><span class="c0">ou ferramentas da </span><span class="c2">REDE AFILIO</span
        ><span class="c0"
          >, por cadastros recusados, direcionamentos n&atilde;o processados pelo sistema ou por qualquer outra
          circunst&acirc;ncia alheia &agrave; vontade da </span
        ><span class="c2">CONTRATADA</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="35">
      <li class="c27 c60">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0"
          >ou seus usu&aacute;rios n&atilde;o poder&atilde;o imputar responsabilidade alguma &agrave; </span
        ><span class="c2">CONTRATADA</span
        ><span class="c0"
          >, nem exigir qualquer tipo de indeniza&ccedil;&atilde;o e repara&ccedil;&atilde;o em virtude de eventuais
          preju&iacute;zos resultantes das dificuldades mencionadas no item 9.1, bem como por danos indiretos que surjam
          em conex&atilde;o com o presente Contrato, incluindo-se o caso de as ditas falhas afetarem os valores que lhe
          devam ser creditados.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="36">
      <li class="c8">
        <span class="c0"
          >As responsabilidades que possam surgir em decorr&ecirc;ncia destas circunst&acirc;ncias e da </span
        ><span class="c2">REDE AFILIO</span
        ><span class="c0">, n&atilde;o exceder&atilde;o o total dos pagamentos feitos ou por fazer ao </span
        ><span class="c2">AFILIADO</span><span class="c0">, conforme os termos deste Contrato.</span>
      </li>
    </ol>
    <p class="c22"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="10">
      <li class="c20 li-bullet-3">
        <h1 style="display: inline">
          <span class="c2">Da Aceita&ccedil;&atilde;o das Condi&ccedil;&otilde;es do Contrato</span>
        </h1>
      </li>
    </ol>
    <p class="c3"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c19">
        <span class="c0"
          >Ao pressionar o bot&atilde;o designado como &ldquo;ACEITO&rdquo; ao final deste Contrato, o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >reconhece haver lido o presente Contrato e aceitado todos seus termos e condi&ccedil;&otilde;es. Considera-se
          que tenha avaliado de forma livre e independente tais condi&ccedil;&otilde;es e que sua inten&ccedil;&atilde;o
          de participar da </span
        ><span class="c2">REDE AFILIO </span
        ><span class="c0"
          >n&atilde;o est&aacute; relacionada com alguma outra manifesta&ccedil;&atilde;o, garantia, ou
          declara&ccedil;&atilde;o que n&atilde;o sejam as estabelecidas neste Contrato.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="11">
      <li class="c20 li-bullet-3">
        <h1 style="display: inline">
          <span class="c2">Prazo de Vig&ecirc;ncia e Rescis&atilde;o</span>
        </h1>
      </li>
    </ol>
    <p class="c22"><span class="c11"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c15 c27">
        <span class="c0"
          >O presente contrato vigora por prazo indeterminado, podendo ser rescindido de pleno direito por qualquer das
          partes, mediante comunica&ccedil;&atilde;o por escrito ou eletr&ocirc;nica, independentemente de
          observ&acirc;ncia de quaisquer prazos e de qualquer sorte de indeniza&ccedil;&atilde;o ou ressarcimento.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="39">
      <li class="c31 c27">
        <span class="c0"
          >A rescis&atilde;o do presente Contrato por iniciativa de qualquer uma das partes implica necessariamente na
          exclus&atilde;o do </span
        ><span class="c2">AFILIADO </span><span class="c0">da </span><span class="c2">REDE AFILIO </span
        ><span class="c0">e a perda de sua condi&ccedil;&atilde;o de </span><span class="c2">AFILIADO</span
        ><span class="c0">.</span>
      </li>
    </ol>
    <p class="c3"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-0" start="12">
      <li class="c20 li-bullet-3">
        <h1 style="display: inline">
          <span class="c2">Disposi&ccedil;&otilde;es Gerais</span>
        </h1>
      </li>
    </ol>
    <p class="c16"><span class="c61 c68"></span></p>
    <ol class="c1 lst-kix_list_1-1 start">
      <li class="c27 c66">
        <span class="c0">A </span><span class="c2">CONTRATADA </span><span class="c0">e o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0"
          >declaram serem contratantes independentes, sem nenhuma rela&ccedil;&atilde;o societ&aacute;ria ou comercial
          fora a pactuada no presente Contrato sendo que cada um preservar&aacute; o outro de qualquer
          reclama&ccedil;&atilde;o derivada das obriga&ccedil;&otilde;es tribut&aacute;rias, trabalhistas ou
          previdenci&aacute;rias que estiverem a seu encargo.</span
        >
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="41">
      <li class="c31 c27">
        <span class="c0">A </span><span class="c2">CONTRATADA </span
        ><span class="c0"
          >poder&aacute; alterar a qualquer momento os termos e condi&ccedil;&otilde;es deste Contrato e
          notificar&aacute; as altera&ccedil;&otilde;es ao </span
        ><span class="c2">AFILIADO </span><span class="c0">publicando uma vers&atilde;o atualizada do Contrato na </span
        ><span class="c2">REDE AFILIO</span><span class="c0">.</span>
      </li>
    </ol>
    <p class="c16"><span class="c0"></span></p>
    <ol class="c1 lst-kix_list_1-2 start">
      <li class="c27 c67">
        <span class="c0">O </span><span class="c2">AFILIADO </span
        ><span class="c0">dever&aacute; comunicar atrav&eacute;s do </span><span class="c13">webmail </span
        ><span class="c0"
          >dentro de 5 (cinco) dias de publicadas as modifica&ccedil;&otilde;es, se n&atilde;o as aceita, ficando a
          partir desse momento, dissolvido o v&iacute;nculo contratual. Vencido este prazo, se considerar&aacute; que o </span
        ><span class="c2">AFILIADO </span
        ><span class="c0">aceita os novos termos e o Contrato continuar&aacute; vinculando as partes.</span>
      </li>
    </ol>
    <p class="c16"><span class="c6"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="42">
      <li class="c31 c27">
        <span class="c0">Este Contrato ser&aacute; regido exclusivamente pelas leis brasileiras.</span>
      </li>
    </ol>
    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="43">
      <li class="c31 c27">
        <span class="c0"
          >A aceita&ccedil;&atilde;o deste Contrato substitui todos e quaisquer contratos anteriormente firmados entre
          as partes.</span
        >
      </li>
    </ol>
    <p class="c7"><span class="c12"></span></p>
    <ol class="c1 lst-kix_list_1-1" start="44">
      <li class="c27 c54">
        <span class="c0"
          >Qualquer controv&eacute;rsia derivada do presente Contrato ser&aacute; submetida a uma das Varas
          C&iacute;veis do Foro Regional do Rio de Janeiro/RJ.</span
        >
      </li>
    </ol>
    <div>
      <p class="c38">
        <span
          style="
            overflow: hidden;
            display: inline-block;
            margin: 0px 0px;
            border: 0px solid #000000;
            transform: rotate(0rad) translateZ(0px);
            -webkit-transform: rotate(0rad) translateZ(0px);
            width: 17px;
            height: 21.4px;
          "
        ></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermPt'
};
</script>

<style>
/* @import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98"); */
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) ' ';
}
.lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) ' ';
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '\002022  ';
}
.lst-kix_list_1-4 > li:before {
  content: '\002022  ';
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
li.li-bullet-1:before {
  margin-left: -16.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 16.6pt;
}
.lst-kix_list_1-7 > li:before {
  content: '\002022  ';
}
li.li-bullet-3:before {
  margin-left: -16.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 16.4pt;
}
.lst-kix_list_1-5 > li:before {
  content: '\002022  ';
}
.lst-kix_list_1-6 > li:before {
  content: '\002022  ';
}
li.li-bullet-0:before {
  margin-left: -11.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.1pt;
}
li.li-bullet-2:before {
  margin-left: -16.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 16.6pt;
}
li.li-bullet-4:before {
  margin-left: -21.9pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.9pt;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_1-8 > li:before {
  content: '\002022  ';
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c18 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c23 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.8pt;
}
.c8 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.c10 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.c19 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c45 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c50 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c5 {
  color: #0d0d0d;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c56 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c11 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c26 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c6 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c59 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: italic;
}
.c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c41 {
  color: #0d0d0d;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial MT';
  font-style: normal;
}
.c4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial MT';
  font-style: normal;
}
.c52 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c60 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.3pt;
}
.c68 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.c46 {
  margin-left: 23.1pt;
  padding-top: 0pt;
  padding-left: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c66 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.c31 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.c28 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.c64 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c33 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.3pt;
}
.c35 {
  margin-left: 21.4pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.c67 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.c51 {
  margin-left: 5.2pt;
  padding-top: 2.9pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.c37 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.7pt;
}
.c44 {
  margin-left: 21.4pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1375;
  text-align: justify;
  margin-right: 5.5pt;
}
.c65 {
  margin-left: 21.4pt;
  padding-top: 2.9pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c63 {
  margin-left: 23pt;
  padding-top: 0.1pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c20 {
  margin-left: 23pt;
  padding-top: 0.1pt;
  padding-left: -1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c34 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  text-align: justify;
  margin-right: 5.5pt;
}
.c58 {
  margin-left: 23pt;
  padding-top: 2.9pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c15 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c62 {
  margin-left: 159.1pt;
  padding-top: 1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
  margin-right: 153.9pt;
}
.c54 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.8pt;
}
.c55 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.c47 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.c48 {
  margin-left: 21.4pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.c24 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c57 {
  margin-left: 23.2pt;
  padding-top: 2.9pt;
  padding-left: -1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c9 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c39 {
  color: #0d0d0d;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: 'Calibri';
  font-style: normal;
}
.c32 {
  margin-left: 23.1pt;
  padding-top: 2.9pt;
  padding-left: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c69 {
  margin-left: 23.1pt;
  padding-top: 0pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c49 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.c36 {
  margin-left: 5.2pt;
  padding-top: 2.4pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.c42 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c43 {
  margin-left: 23.1pt;
  padding-top: 2.4pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c29 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c22 {
  padding-top: 0.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c21 {
  padding-top: 0.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c3 {
  padding-top: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c40 {
  margin-left: 5.2pt;
  padding-top: 2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c16 {
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c30 {
  margin-left: 5.2pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c7 {
  padding-top: 0.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c38 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: left;
}
.c53 {
  background-color: #ffffff;
  max-width: 451.5pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c27 {
  list-style-position: inside;
  text-indent: 45pt;
}
.c1 {
  padding: 0;
  margin: 0;
}
.c14 {
  color: inherit;
  text-decoration: inherit;
}
.c25 {
  font-size: 11pt;
}
.c61 {
  font-weight: 700;
}
.title {
  padding-top: 0.5pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: 'Calibri';
  line-height: 1;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
</style>
