<template>
  <div>
    <h1 class="cb51">
      <span class="cb2">VIRTUAL SPACE LEASE CONTRACT</span>
    </h1>
    <p class="cb36"><span class="cb2"></span></p>
    <p class="cb18"><span class="cb39"></span></p>
    <p class="cb66">
      <span class="cb4">On the one hand, </span><span class="cb2">HI-M&Iacute;DIA INTERNET LTDA, </span
      ><span class="cb4"
        >enrolled with the CNPJ (Corporate Taxpayer Registration) under No. 07.456.778/0001-59, headquartered in the
        city and state of Rio de Janeiro, at Rua Humaita, 275 - 6º andar/7º andar, Humaita, CEP (Zip code): 22.261-005
        (&quot;</span
      ><span class="cb2">CONTRACTOR</span
      ><span class="cb4"
        >&quot;) and, on the other hand, all individuals or legal entities, of legal age, who have their registration
        approved to be admitted to the </span
      ><span class="cb2">AFILIO NETWORK </span><span class="cb4">(&quot;</span><span class="cb2">AFFILIATE</span
      ><span class="cb4">&quot;), have agreed to this Virtual Space Lease Contract (&ldquo;</span
      ><span class="cb2">Contract </span
      ><span class="cb4">&rdquo;), which presents the general terms and conditions established between the </span
      ><span class="cb2">CONTRACTOR </span><span class="cb4">and the </span><span class="cb2">AFFILIATE </span
      ><span class="cb4">regarding the registration and admission to the </span><span class="cb2">AFILIO NETWORK</span
      ><span class="cb4">.</span>
    </p>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0 start" start="1">
      <li class="cb22 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">General Conditions</span>
        </h1>
      </li>
    </ol>
    <p class="cb17"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1 start" start="1">
      <li class="cb43">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">has as its activity the leasing of spaces for the broadcasting of advertising (&quot;</span
        ><span class="cb2">Advertising Space</span><span class="cb4">&quot;) in virtual spaces such as </span
        ><span class="cb13">sites</span><span class="cb4">, </span><span class="cb13">homepages</span
        ><span class="cb4">, </span><span class="cb13">websites</span><span class="cb4">, </span
        ><span class="cb13">emails </span><span class="cb4">(&quot;</span><span class="cb2">Virtual Space</span
        ><span class="cb4">&quot;), owned by the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >or for which the rights of use have been assigned to them, and the sublease of these Advertising Spaces to
          third parties (&ldquo;</span
        ><span class="cb2">Advertisers</span
        ><span class="cb4"
          >&rdquo;) who are interested in advertising their products and services via &#39;affiliate </span
        ><span class="cb13">marketing</span><span class="cb4">&#39; (&ldquo;</span><span class="cb2">Advertising</span
        ><span class="cb4">&rdquo;).</span>
      </li>
    </ol>
    <p class="cb9"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2 start" start="1">
      <li class="cb15">
        <span class="cb4"
          >The activities mentioned in item 1.1 and the legal and business relationships created between the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">and the </span><span class="cb2">AFFILIATES </span
        ><span class="cb4">are developed in a platform called &quot;</span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >&quot;, which, for the purposes of this Contract, shall be defined as a &quot;tool that provides leasing
          opportunities to </span
        ><span class="cb2">AFFILIATES</span
        ><span class="cb4">, monitoring all their transactions in real time&quot;.</span>
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="2">
      <li class="cb8">
        <span class="cb4">To become an </span><span class="cb2">AFFILIATE</span
        ><span class="cb4">, it is necessary to request to be admitted to the </span
        ><span class="cb2">AFILIO NETWORK </span><span class="cb4">by filling out and submitting to the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">the registration form available on its </span
        ><span class="cb13">website</span><span class="cb4">. The requests to be admitted to the </span
        ><span class="cb2">AFILIO NETWORK </span><span class="cb4">will be evaluated and confirmed by the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >within three (3) business days, counted from the date of submission of the registration.</span
        >
      </li>
    </ol>
    <p class="cb17"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="2">
      <li class="cb49">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >reserves the right to refuse any request for registration and to cancel any previously accepted registration,
          without being obliged to communicate or state the reasons for its decision, and without this generating to the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">any duty or obligation of damages or compensation of any kind against any person.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="3">
      <li class="cb40">
        <span class="cb4">The refusal of registration by the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">implies the impediment of the registration applicant to be admitted to the </span
        ><span class="cb2">AFILIO NETWORK </span><span class="cb4">and, consequently, to acquire the status of </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >, which also implies the impediment of the applicant to request a new registration using other Virtual Spaces
          owned by it or by third parties that have granted it the rights to do so.</span
        >
      </li>
    </ol>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="3">
      <li class="cb35">
        <span class="cb4">After the approval of the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">&#39;s admission to the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">, the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">shall still approve, at its discretion, the Advertising Space of the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">for the broadcast of each Advertisement.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="4">
      <li class="cb35">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >is the provider and the only and exclusive responsible for the Virtual Space with the ability to broadcast
          Advertisements registered by it in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">, and leased to the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">, according to this Contract.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="2">
      <li class="cb22 li-bullet-0">
        <h1 style="display: inline"><span class="cb2">Purpose</span></h1>
      </li>
    </ol>
    <p class="cb17"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="5">
      <li class="cb8">
        <span class="cb4">The purpose of this Contract is the leasing of the Advertising Space by the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">to the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">and the authorization of the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">to the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">so that the latter can sublease, assign, or lend the</span>
      </li>
    </ol>
    <p class="cb50">
      <span class="cb4">Advertising Space to </span><span class="cb2">ADVERTISERS</span
      ><span class="cb4"
        >, for the purpose of Advertising broadcast, upon payment of compensation pursuant to Clause 3 of this
        Contract.</span
      >
    </p>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="6">
      <li class="cb57">
        <span class="cb4"
          >For the purposes of this Contract, the Advertising Space shall be considered as subleased (&quot;</span
        ><span class="cb2">Subleased Space</span><span class="cb4">&quot;) when advertisements of </span
        ><span class="cb2">ADVERTISERS </span><span class="cb4">are broadcast on it, through the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="3">
      <li class="cb25 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">Compensation and Payment Method</span>
        </h1>
      </li>
    </ol>
    <p class="cb14"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="7">
      <li class="cb35">
        <span class="cb4">The amount of compensation for leasing the Advertising Space (&quot;</span
        ><span class="cb2">Compensation</span
        ><span class="cb4">&quot;) will be determined based on the performance of the Advertising (&ldquo;</span
        ><span class="cb2">Advertising Performance</span
        ><span class="cb4">&rdquo;) and in accordance with the advertising model established in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="4">
      <li class="cb15">
        <span class="cb4"
          >Advertising Performance is the achievement of its objective, which may be characterized by advertising
          &#39;clicks&#39;, viewing, registration, commercial transactions, or other similar actions, which represent an
          internet user&#39;s interaction with the Advertising.</span
        >
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="5">
      <li class="cb15">
        <span class="cb4">The mentioned actions are defined and detailed in the </span
        ><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4">and may be different for each type of Advertising to be broadcast.</span>
      </li>
    </ol>
    <p class="cb17"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="6">
      <li class="cb15">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">monitors the actions and makes the information obtained available to the </span
        ><span class="cb2">AFFILIATE</span><span class="cb4">, through a report available in the </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >, for the purpose of calculating the Compensation, and the only valid parameter for calculating the lease
          amount is the monitoring information obtained and made available by the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb4"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="7">
      <li class="cb15">
        <span class="cb4">In case of suspicion of fraud by the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">or by the </span><span class="cb2">ADVERTISERS </span
        ><span class="cb4">on the part of the </span><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >, whether those mentioned in clause 6 below, or any other aimed at receiving greater amounts for the lease
          carried out, or for any other purpose and, even if the fraud is not carried out by the </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >, but facilitated by weaknesses or inadequacies of its Virtual Space, any and all payments that are or may be
          due will be suspended, without prejudice to the </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >&#39;s responsibility for the payment of losses and damages that may arise from the aforementioned
          questioning.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="8">
      <li class="cb8">
        <span class="cb1"
          >Only the amounts of Compensation that correspond to the performance of the Advertising actually carried out
          will be considered due. Any case that generates a non-receipt by the </span
        ><span class="cb20">CONTRACTOR </span><span class="cb1">in relation to the amounts owed by the </span
        ><span class="cb20">ADVERTISERS</span><span class="cb1">, will imply that no amount is due to the </span
        ><span class="cb20">AFFILIATE</span><span class="cb1">.</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="9">
      <li class="cb16">
        <span class="cb4">In order to receive the compensation for the lease, the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">shall issue and send to the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4"
          >, the invoice with the amount of the balance released to it, in the form and within the terms set out in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb26"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="8">
      <li class="cb40">
        <span class="cb1">The </span><span class="cb20">AFFILIATE </span
        ><span class="cb1"
          >has a term of twelve (12) months from the month in which the Advertising is broadcast to forward the
          aforementioned invoice to the respective </span
        ><span class="cb20">CONTRACTOR</span><span class="cb1">. After this period has elapsed, the </span
        ><span class="cb20">AFFILIATE </span
        ><span class="cb1">loses the right to collect the amount of the campaigns of that period.</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="10">
      <li class="cb16" id="h.gjdgxs">
        <span class="cb4">The Compensation will be calculated by the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">with the </span><span class="cb2">ADVERTISERS </span
        ><span class="cb4"
          >within twenty-one (21) days after the end of the month, which, for the purposes of this Contract, is
          considered the last business day of said month.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="9">
      <li class="cb54 li-bullet-1">
        <span class="cb4">Notwithstanding the term established in the item </span
        ><span class="cb4"><a class="cb12" href="#h.gjdgxs">3.4</a></span
        ><span class="cb4">, there may be delays on the part of the </span><span class="cb2">CONTRACTOR</span>
      </li>
    </ol>
    <p class="cb72">
      <span class="cb4">in the calculation and availability of the balances of each </span
      ><span class="cb2">AFFILIATE</span
      ><span class="cb4">, without this characterizing any contractual breach by the </span
      ><span class="cb2">CONTRACTOR </span
      ><span class="cb4">and without any burden or penalties being imposed on it.</span>
    </p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb14"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="11">
      <li class="cb35" id="h.30j0zll">
        <span class="cb4"
          >The payment of the compensation for the lease of the Advertising Space will be made monthly, but will
          effectively occur only when the credit of the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">included in the </span
        ><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4">is equal to or greater than five hundred reais (R$ 500.00).</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="10">
      <li class="cb40">
        <span class="cb4">If the minimum amount mentioned in the item </span
        ><span class="cb4"><a class="cb12" href="#h.30j0zll">3.5</a></span
        ><span class="cb4">&nbsp;is not reached by the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >in the month in question, or if the payment of the month in question is not made due to the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >not sending the invoice (item 3.3), even if the minimum amount is reached, the balance of the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">will be kept as a credit in the </span
        ><span class="cb2">AFILIO NETWORK </span><span class="cb4">for the next month.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="12">
      <li class="cb0">
        <span class="cb4"
          >After the end of each month, the Advertising Performance is determined, and the minimum amount is set, the
          payment of the Compensation to the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">will be made by the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4"
          >, being certain that the taxes levied under the law will be deducted from the amount paid, as well as
          administrative costs listed in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="13">
      <li class="cb35">
        <span class="cb4">The payment of the Compensation will be made by the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">through direct bank transfer to the checking account previously indicated by the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">in the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >, provided that said account is Brazilian, and the bank fees eventually charged in the bank transfer
          transaction will be deducted from the Compensation amount.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="11">
      <li class="cb34">
        <span class="cb4">If the bank account indicated by the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >is foreign, the payment of the compensation will be made by means of an exchange transfer with the Central
          Bank of Brazil, and the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >shall be solely responsible for the payment of any and all costs arising from the aforementioned transaction,
          including the taxes that may be levied on the transaction.</span
        >
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="12">
      <li class="cb42">
        <span class="cb4">The compensation amounts will be paid by the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">in Brazilian currency (Real), with the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >being responsible for any costs arising from the exchange rate change and conversion of the currency. The
          conversion date will be considered as the exchange closing date with the Central Bank of Brazil and the rates
          will be those determined by the Central Bank of Brazil.</span
        >
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="4">
      <li class="cb25 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">Responsibilities of the Affiliate</span>
        </h1>
      </li>
    </ol>
    <p class="cb36"><span class="cb32"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="14">
      <li class="cb28">
        <span class="cb4">During the term of this Contract, the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >is prohibited from carrying out new contracts or negotiations, directly with the Advertisers of the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">, involving the use of its Advertising Space.</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="13">
      <li class="cb15">
        <span class="cb4">If the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >fails to comply with the obligation mentioned in item 40.1, it shall incur the payment of a fine equivalent
          to one hundred percent (100%) of the amount of the Compensation due in the month in which the non-compliance
          is verified.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="14">
      <li class="cb15">
        <span class="cb4">In the event of a fine being verified, the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >is authorized to deduct the total or partial amount of the fine applied from the payments that the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">is entitled to receive, or from the credits it has in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="15">
      <li class="cb16">
        <span class="cb4">Registration on the </span><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4">is personal and non-transferable, and no other person can have access to the </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4">&#39;s data, which shall immediately communicate to the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">through the email</span>
      </li>
    </ol>
    <p class="cb65">
      <span class="cb4"><a class="cb12" href="mailto:contato@afil.io">contato@afil.io, </a></span
      ><span class="cb4">if it becomes aware of any access by third parties to its data.</span>
    </p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb26"><span class="cb60"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="16">
      <li class="cb56">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">is responsible for all information entered by it in its registration on the </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >, being responsible for the veracity of the mentioned information and undertaking itself to always keep it up
          to date.</span
        >
      </li>
    </ol>
    <p class="cb18"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="17">
      <li class="cb35">
        <span class="cb4">If the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >disposes or binds its rights on the Advertising Space to third parties not related to this Contract or to the
          activities developed in the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">, the </span><span class="cb2">Affiliate </span
        ><span class="cb4"
          >shall be obliged to inform the third party of the terms of this Contract, being responsible for ensuring that
          it fully complies with them, as well as any damages caused by it.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="18">
      <li class="cb8">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >is solely and exclusively responsible for the content of its Virtual Space, and if users or any other persons
          file any type of lawsuits or legal claims relating to the content or any other aspects related to the Virtual
          Space, the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">shall be obliged to hold the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">harmless from any responsibility, an obligation that extends to the </span
        ><span class="cb2">CONTRACTOR&#39;</span
        ><span class="cb4">s officers, managers, employees, representatives, associates and agents, and the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">shall be obliged to repair the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >for the costs it incurs as a result of the aforementioned actions or claims, without prejudice to the
          compensation for possible losses and damages.</span
        >
      </li>
    </ol>
    <p class="cb14"><span class="cb3"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="19">
      <li class="cb67">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >is also responsible for providing the unsubscription, from its database, of Users or third-party final
          recipients of the Advertising, within twenty-four hours (24h) after receiving an </span
        ><span class="cb13">opt-out </span
        ><span class="cb4">request, whether this request comes from the User or third party, as coming from the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4"
          >, under penalty of, not doing so within the set term, incurring the payment of the fine provided for in item
          4.1.1 above, with the exception that, if the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">is administratively or judicially sued for facts attributable to the </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >, it may implead the latter, without prejudice to the right of recourse for all damages and costs
          arising.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="5">
      <li class="cb11 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">Confidentiality of Information</span>
        </h1>
      </li>
    </ol>
    <p class="cb26"><span class="cb6"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="20">
      <li class="cb8">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">is responsible for protecting the privacy of the information offered by the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">(&ldquo;</span><span class="cb2">Information</span
        ><span class="cb4">&rdquo;) on the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >, except when the Information is requested by court orders, official requirements, or legal
          requirements.</span
        >
      </li>
    </ol>
    <p class="cb36"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="15">
      <li class="cb68">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >shall in no event be responsible for the disclosure of Information if Users or third parties eventually
          intercept or access it illegally.</span
        >
      </li>
    </ol>
    <p class="cb36"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="21">
      <li class="cb0">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >undertakes to maintain in strict confidentiality all information to which it has access as an </span
        ><span class="cb2">AFFILIATE</span><span class="cb4">, including details regarding the </span
        ><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4"
          >&#39;s tools, promotion structures, among other information, and this obligation will extend for a period of
          two (2) years after the end of the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">relationship, under penalty of being obliged to indemnify for losses and damages.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="6">
      <li class="cb11 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">Prohibitions and Frauds</span>
        </h1>
      </li>
    </ol>
    <p class="cb14"><span class="cb6"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="22">
      <li class="cb38 li-bullet-2">
        <span class="cb4">The following practices will not be tolerated by the </span><span class="cb2">CONTRACTOR</span
        ><span class="cb4">:</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb3"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="16">
      <li class="cb68">
        <span class="cb4"
          >Use of systems in which the registration is involuntary and/or automatic on the websites of the advertisers
          of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">;</span>
      </li>
      <li class="cb64">
        <span class="cb4"
          >Offering money or any type of articles, products, advantages, gifts, or services and/or carrying out any
          promotion in order to generate sales, actions, or registrations on the sites of the advertisers of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">, without prior authorization;</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="18">
      <li class="cb15">
        <span class="cb4">Indiscriminate sending of emails in order to promote a product registered on one of the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4">&#39;s advertisers&#39; websites or to generate access to the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4">&#39;s advertisers&#39; websites or the mass sending of </span><span class="cb13">spam </span
        ><span class="cb4">emails under any condition;</span>
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="19">
      <li class="cb15">
        <span class="cb4"
          >Sending of correspondence, electronic or otherwise, that misleads the recipient into error or confusion about
          the sender of the email, leading them to believe that the sender is the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">itself or one of the Advertisers, or using the trademarks of the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">and the Advertisers of its affiliation platform, without prior authorization;</span>
      </li>
    </ol>
    <p class="cb17"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="20">
      <li class="cb40">
        <span class="cb4"
          >Use of third-party registration to defraud the system and, in some way, break the prohibitions described
          herein or in the General Terms and Conditions of other </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">&#39;s policies;</span>
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="21">
      <li class="cb15">
        <span class="cb4">Use any mechanism that seeks to obtain compensation from the </span
        ><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4"
          >without proper promotion from its Advertisers, such as, but not limited to, indiscriminately placing </span
        ><span class="cb13">cookies</span
        ><span class="cb4">, without the Internet user receiving the aforementioned </span
        ><span class="cb13">cookie </span
        ><span class="cb4">having clicked on any advertising pieces published through the </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">; create websites with domains that bear a phonetic or semantic similarity with the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">and the Advertisers of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">;</span>
      </li>
    </ol>
    <p class="cb9"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="22">
      <li class="cb15">
        <span class="cb4"
          >Contract with any search engines the purchase of &#39;sponsored links&#39; as a way of generating traffic to
          the websites of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">&#39;s </span><span class="cb2">ADVISERS</span
        ><span class="cb4">, except when the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >has expressly authorized such contracting, through a written instrument that will indicate the limits for
          such contracting.</span
        >
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="23">
      <li class="cb8">
        <span class="cb4">The practice of any of these infringements will imply to the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">not only the responsibility for all the consequences that the act may cause to the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">or to third parties, as well as implying the suspension or cancellation of its </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">status, at the discretion of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="24">
      <li class="cb8">
        <span class="cb4">The existence of evidence on the part of the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">or any advertiser that the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >practiced any of the activities mentioned in clause 6.1, or any other, which in some way may be configured as
          an attempt to fraud the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >&#39;s business format properly described hereto, will give rise to the possibility of the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">to withhold, for up to six (6) months, any payments that it has to make to the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >in accordance with clauses 3 and 4, being hereby authorized the compensation of the withheld amounts with any
          losses that have been caused by demonstrably inadequate practices. Once the withholding has been carried out,
          the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >will be notified, so that it can explain the practice that generated the evidence, and the release of the
          withheld amounts will occur if the matter is duly clarified, at the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">&#39;s discretion.</span>
      </li>
    </ol>
    <p class="cb36"><span class="cb4"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="7">
      <li class="cb25 li-bullet-3">
        <h1 style="display: inline">
          <span class="cb2">Contractor&#39;s Tools</span>
        </h1>
      </li>
    </ol>
    <p class="cb9"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="25">
      <li class="cb41">
        <span class="cb4">When notified of its acceptance and admission to the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">, the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">may place in its Virtual Space </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >&#39;s tools and/or materials, with no limit on quantity, which will contain the identification of the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">and other parameters required to direct visitors of its page to the websites of the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4"
          >&#39;s Advisers, with their respective categories or advertisements according to the different functions that
          each one has, as well as enabling the necessary monitoring for the correct credit of payments. The materials
          may have several formats and are available on the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
      <li class="cb46">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span><span class="cb4">shall make </span
        ><span class="cb13">banners</span><span class="cb4">, </span><span class="cb13">links, </span
        ><span class="cb4">and other advertising pieces available on the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">, so that the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">can download and publish them in its Virtual Space.</span>
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="27">
      <li class="cb8">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">shall duly copy the code informed next to the links to </span
        ><span class="cb13">download </span><span class="cb4">the </span><span class="cb13">banners</span
        ><span class="cb4">. This code enables the identification of the </span><span class="cb2">AFFILIATE</span
        ><span class="cb4">&#39;s Virtual Space and the accounting of sales from it. The </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">is also responsible for the location chosen for the publication of the </span
        ><span class="cb13">banners </span
        ><span class="cb4">and for the proper insertion of the code, which requires basic knowledge of </span
        ><span class="cb13">Hyper Text Markup Language </span><span class="cb4">(HTML).</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="28">
      <li class="cb28">
        <span class="cb4">It is hereby established that the </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">will use its </span><span class="cb2">AFILIO PLATFORM </span
        ><span class="cb4"
          >to count and confirm clicks, registrations, sales, and also to monitor the User&#39;s activity, based on </span
        ><span class="cb13">cookies</span><span class="cb4">. Since the permanence and existence of </span
        ><span class="cb13">cookies </span
        ><span class="cb4">on the Users&#39; computer depends solely on their will, the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">is not responsible if the Users delete or do not accept the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">&#39;s </span><span class="cb13">cookies</span
        ><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb9"><span class="cb58"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="29">
      <li class="cb47">
        <span class="cb4"
          >Modification of the HTML codes provided will only be allowed with the express written authorization of the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4">. Account control and payment verification will be carried out strictly by the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">. The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4">shall only be responsible for the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">&#39;s tools created and made available directly by it.</span>
      </li>
    </ol>
    <p class="cb36"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="30">
      <li class="cb8">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >represents that it is aware that, under the terms defined in the legislation in force, including, depending
          on what is applicable in each case, the General Data Protection Law No. 13.709/18 (LGPD) or the General Data
          Protection Regulation No. 2016 /679 (GDPR), its data will be accessed by the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4">, in particular, but not limited to the case of an individual </span
        ><span class="cb2">AFFILIATE</span
        ><span class="cb4"
          >, under the terms of this Contract solely and exclusively for the performance of this Contract, under the
          terms established herein and through the tracking system mentioned in clause 7.4 above, with which the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">expressly agrees. Thus, the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">is not responsible for the information transmitted by the </span
        ><span class="cb2">AFFILIATES</span
        ><span class="cb4"
          >, safeguarding the right to be compensated in the event of being eventually held responsible for irregular or
          incorrect information from campaigns broadcast on the </span
        ><span class="cb2">AFFILIATE</span><span class="cb4">&#39;s channels or Social Networks in general.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb33"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="8">
      <li class="cb25 li-bullet-3">
        <h1 style="display: inline">
          <span class="cb2">Limitation of License</span>
        </h1>
      </li>
    </ol>
    <p class="cb26"><span class="cb55"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="31">
      <li class="cb8">
        <span class="cb4">All intellectual and industrial rights over the </span><span class="cb13">website</span
        ><span class="cb4">, operations, </span><span class="cb13">software</span><span class="cb4">, </span
        ><span class="cb13">hardware</span><span class="cb4">, domain, brand logos, emblems, logos, page </span
        ><span class="cb13">design </span
        ><span class="cb4">and advertising pieces, structure, contents, information, </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">&#39;s tools, etc. are the absolute property of the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">and the Advertisers.</span>
      </li>
    </ol>
    <p class="cb44"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="32">
      <li class="cb35">
        <span class="cb4">In no case will it be understood that the </span><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >has any kind of right over the rights mentioned in item 8.1, regardless of the rights granted on the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">&#39;s tools that the </span
        ><span class="cb2">CONTRACTOR </span><span class="cb4">makes available to the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">during the term of this Contract.</span>
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="33">
      <li class="cb53">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span><span class="cb4">only authorizes the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">to make use of its intellectual property regarding the </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >&#39;s tools made available to it for the fulfillment of the activities that derive from this Contract. Any
          other use of such intellectual property of the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">is strictly prohibited. To the extent that these limitations are met, the </span
        ><span class="cb2">CONTRACTOR </span
        ><span class="cb4">grants a free, non-exclusive, and revocable license to the </span
        ><span class="cb2">AFFILIATE</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb18"><span class="cb59"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="9">
      <li class="cb25 li-bullet-0">
        <h1 style="display: inline">
          <span class="cb2">Limitation of Responsibility</span>
        </h1>
      </li>
    </ol>
    <ol class="cb10 lst-kix_list_1-1 start" start="1">
      <li class="cb46">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >does not guarantee access and continued or uninterrupted use of its website, and the system may eventually
          not be available due to technical difficulties or </span
        ><span class="cb13">internet </span><span class="cb4">failures in the </span><span class="cb13">links </span
        ><span class="cb4">or tools of the </span><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4"
          >, due to declined registrations, directions not processed by the system or by any other circumstance beyond
          the control of the </span
        ><span class="cb2">CONTRACTOR</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="2">
      <li class="cb43">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">or its users may not attribute any responsibility to the </span
        ><span class="cb2">CONTRACTOR</span
        ><span class="cb4"
          >, nor demand any type of indemnification and redress due to any damages resulting from the difficulties
          mentioned in item 9.1, as well as for indirect damages that arise in connection with this Contract, including
          the case that said failures affect the amounts that should be credited to it.</span
        >
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="3">
      <li class="cb62">
        <span class="cb4">Responsibilities that may arise as a result of these circumstances and the </span
        ><span class="cb2">AFILIO NETWORK</span
        ><span class="cb4">, will not exceed the total payments made or to be made to the </span
        ><span class="cb2">AFFILIATE </span><span class="cb4">pursuant to the terms of this Contract.</span>
      </li>
    </ol>
    <p class="cb26"><span class="cb74"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="10">
      <li class="cb24 li-bullet-4">
        <h1 style="display: inline">
          <span class="cb2">Acceptance of the Conditions of Contract</span>
        </h1>
      </li>
    </ol>
    <p class="cb14"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="4">
      <li class="cb41">
        <span class="cb4"
          >By pressing the button designated as &quot;I ACCEPT&quot; at the end of this Contract, the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >acknowledges having read this Contract and accepting all its terms and conditions. It is considered that you
          have freely and independently evaluated such conditions and that your intention to participate in the </span
        ><span class="cb2">AFILIO NETWORK </span
        ><span class="cb4"
          >is not related to any other manifestation, guarantee, or statement other than those established in this
          Contract.</span
        >
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="11">
      <li class="cb19 li-bullet-2">
        <h1 style="display: inline">
          <span class="cb2">Term and Termination</span>
        </h1>
      </li>
    </ol>
    <p class="cb9"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="5">
      <li class="cb23">
        <span class="cb4"
          >This contract is in force for an indefinite term, and may be terminated by either party, by means of written
          or electronic communication, regardless of compliance with any terms and any type of indemnity or
          compensation.</span
        >
      </li>
    </ol>
    <p class="cb14"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="6">
      <li class="cb45 li-bullet-5">
        <span class="cb4"
          >The termination of this Contract by initiative of either party necessarily implies the exclusion of the </span
        ><span class="cb2">AFFILIATE</span>
      </li>
    </ol>
    <p class="cb29">
      <span class="cb48">from the </span><span class="cb48 c69">AFILIO NETWORK </span
      ><span class="cb48">and the loss of its </span><span class="cb48 c69">AFFILIATE </span
      ><span class="cb4">condition.</span>
    </p>
    <p class="cb9"><span class="cb3"></span></p>
    <ol class="cb10 lst-kix_list_1-0" start="12">
      <li class="cb31 li-bullet-2">
        <h1 style="display: inline">
          <span class="cb2">Miscellaneous</span>
        </h1>
      </li>
    </ol>
    <p class="cb9"><span class="cb5"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="7">
      <li class="cb8">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span><span class="cb4">and the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >represent that they are independent contractors, without any corporate or commercial relationship other than
          that agreed in this Contract, and each party shall preserve the other from any claim arising from the tax,
          labor, or social security obligations that they are responsible for.</span
        >
      </li>
    </ol>
    <p class="cb18"><span class="cb7"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="8">
      <li class="cb23">
        <span class="cb4">The </span><span class="cb2">CONTRACTOR </span
        ><span class="cb4"
          >may amend the terms and conditions of this Contract at any time and shall notify the amendments to the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4">by publishing an updated version of the Contract on the </span
        ><span class="cb2">AFILIO NETWORK</span><span class="cb4">.</span>
      </li>
    </ol>
    <p class="cb18"><span class="cb4"></span></p>
    <ol class="cb10 lst-kix_list_1-2" start="23">
      <li class="cb34">
        <span class="cb4">The </span><span class="cb2">AFFILIATE </span
        ><span class="cb4">shall communicate through </span><span class="cb13">webmail </span
        ><span class="cb4"
          >within five (5) days of publishing the amendments, if it does not accept them, and from that moment on, the
          contractual relationship will be dissolved. Once the term has expired, the </span
        ><span class="cb2">AFFILIATE </span
        ><span class="cb4"
          >will be considered to have accepted the new terms and the Contract will continue to bind the parties.</span
        >
      </li>
    </ol>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb36"><span class="cb4"></span></p>
    <p class="cb26"><span class="cb30"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="9">
      <li class="cb73 li-bullet-6">
        <span class="cb4">This Contract shall be governed exclusively by the Brazilian laws.</span>
      </li>
      <li class="cb70 li-bullet-7">
        <span class="cb4"
          >Acceptance of this Contract replaces any and all contracts previously entered into between the parties.</span
        >
      </li>
    </ol>
    <p class="cb14"><span class="cb3"></span></p>
    <ol class="cb10 lst-kix_list_1-1" start="11">
      <li class="cb37">
        <span class="cb4"
          >Any dispute arising from this Contract will be submitted to one of the Civil Courts of the Regional
          Jurisdiction of Rio de Janeiro/RJ.</span
        >
      </li>
    </ol>
    <div>
      <p class="cb52">
        <span
          style="
            overflow: hidden;
            display: inline-block;
            margin: 0px 0px;
            border: 0px solid #000000;
            transform: rotate(0rad) translateZ(0px);
            -webkit-transform: rotate(0rad) translateZ(0px);
            width: 17px;
            height: 21.4px;
          "
        ></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermEn'
};
</script>

<style>
/* @import url("https://themes.googleusercontent.cbom/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98"); */
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
li.li-bullet-6:before {
  margin-left: -21.9pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.9pt;
}
li.li-bullet-7:before {
  margin-left: -21.9pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.9pt;
}
li.li-bullet-5:before {
  margin-left: -21.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.6pt;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) ' ';
}
.lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, decimal) ' ';
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '\002022  ';
}
.lst-kix_list_1-4 > li:before {
  content: '\002022  ';
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
li.li-bullet-1:before {
  margin-left: -28.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 28.4pt;
}
.lst-kix_list_1-7 > li:before {
  content: '\002022  ';
}
li.li-bullet-3:before {
  margin-left: -11.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.1pt;
}
.lst-kix_list_1-5 > li:before {
  content: '\002022  ';
}
.lst-kix_list_1-6 > li:before {
  content: '\002022  ';
}
li.li-bullet-0:before {
  margin-left: -11.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 11.1pt;
}
li.li-bullet-2:before {
  margin-left: -16.6pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 16.6pt;
}
li.li-bullet-4:before {
  margin-left: -16.4pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 16.4pt;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_1-8 > li:before {
  content: '\002022  ';
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.cb64 {
  margin-left: 21.4pt;
  padding-top: 2.4pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.cb23 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1375;
  text-align: justify;
  margin-right: 5.8pt;
}
.cb40 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.cb53 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.3pt;
}
.cb46 {
  margin-left: 5.2pt;
  padding-top: 2.4pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb28 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.cb35 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.6pt;
}
.cb16 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.cb41 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.3pt;
}
.cb34 {
  margin-left: 21.4pt;
  padding-top: 0.1pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb49 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.cb67 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb15 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb8 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb47 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb37 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.8pt;
}
.cb56 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.8pt;
}
.cb57 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1375;
  text-align: justify;
  margin-right: 5.7pt;
}
.cb62 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1375;
  text-align: justify;
  margin-right: 5.5pt;
}
.cb0 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.7pt;
}
.cb43 {
  margin-left: 5.2pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.4pt;
}
.cb68 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.7pt;
}
.cb42 {
  margin-left: 21.4pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.4pt;
}
.cb1 {
  color: #0d0d0d;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb6 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb55 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb59 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb63 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb58 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb20 {
  color: #0d0d0d;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb21 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial MT';
  font-style: normal;
}
.cb32 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb61 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial MT';
  font-style: normal;
}
.cb7 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb33 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb74 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb30 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb27 {
  color: #0d0d0d;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial MT';
  font-style: normal;
}
.cb39 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: italic;
}
.cb60 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8.5pt;
  font-family: 'Calibri';
  font-style: normal;
}
.cb19 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb66 {
  margin-left: 5.2pt;
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: justify;
  margin-right: 5.2pt;
}
.cb70 {
  margin-left: 23.1pt;
  padding-top: 2.4pt;
  padding-left: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb11 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb54 {
  margin-left: 39.3pt;
  padding-top: 0.1pt;
  padding-left: 10.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.cb31 {
  margin-left: 23pt;
  padding-top: 0.1pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb50 {
  margin-left: 5.2pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.3pt;
}
.cb22 {
  margin-left: 23pt;
  padding-top: 0.1pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.cb45 {
  margin-left: 23.1pt;
  padding-top: 0pt;
  padding-left: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb25 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -6.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.cb51 {
  margin-left: 186.8pt;
  padding-top: 1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
  margin-right: 181.7pt;
}
.cb73 {
  margin-left: 23.1pt;
  padding-top: 0pt;
  padding-left: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb24 {
  margin-left: 23pt;
  padding-top: 0pt;
  padding-left: -1.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb38 {
  margin-left: 23.1pt;
  padding-top: 0pt;
  padding-left: -1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb72 {
  margin-left: 21.4pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left;
  margin-right: 5.3pt;
}
.cb26 {
  padding-top: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb65 {
  margin-left: 5.2pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb9 {
  padding-top: 0.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb17 {
  padding-top: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb18 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb29 {
  margin-left: 5.2pt;
  padding-top: 2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.cb44 {
  padding-top: 0.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb36 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb14 {
  padding-top: 0.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.cb52 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: left;
}
.cb71 {
  background-color: #ffffff;
  max-width: 451.5pt;
  padding: 72pt 72pt 72pt 72pt;
}
.cb10 {
  padding: 0;
  margin: 0;
}
.cb12 {
  color: inherit;
  text-decoration: inherit;
}
.cb48 {
  font-size: 11pt;
}
.cb69 {
  font-weight: 700;
}
.title {
  padding-top: 0.5pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Calibri';
}
h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: 'Calibri';
  line-height: 1;
  text-align: justify;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
</style>
