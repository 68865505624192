<template>
  <q-page class="flex flex-center auth">
    <q-intersection v-show="param == 'login' || !param">
      <login v-if="param == 'login' || !param" />
      <div class="column" style="max-width: 120px">
        <q-select v-model="language" :options="languages" dense borderless>
          <template v-slot:selected>
            <q-item dense class="q-px-none">
              <q-item-section side class="q-pr-sm q-pb-xs">
                <q-img
                  img-class="rounded-borders"
                  :src="require('@/assets/images/flags/' + language.flag)"
                  spinner-color="white"
                  width="24px"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-white">{{ language.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <!-- <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section side>
                                <q-img
                                    img-class="rounded-borders"
                                    :src="
                                        require('@/assets/images/flags/' +
                                            scope.opt.flag)
                                    "
                                    spinner-color="white"
                                    width="24px"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{
                                    scope.opt.label
                                }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template> -->
        </q-select>
      </div>
      <div class="text-center q-pt-md text-caption text-white">
        Copyright© {{ currentYear }} Afilio. Todos os direitos reservados.
        <q-icon name="mdi-cards-heart" color="white" />
      </div>
    </q-intersection>
    <q-intersection v-show="param == 'register'">
      <register-account v-if="param == 'register'" />
      <div class="column" style="max-width: 120px">
        <q-select v-model="language" :options="languages" dense borderless>
          <template v-slot:selected>
            <div class="text-white text-caption">
              {{ language.label }}
            </div>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section side :class="mode !== 'menu' ? 'q-pr-sm' : ''">
                <q-img img-class="rounded-borders" :src="require(scope.opt.flag)" spinner-color="white" width="24px" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
    </q-intersection>
    <q-intersection v-show="param == 'forgot_password'">
      <forgot-password v-if="param == 'forgot_password'" />
    </q-intersection>
    <q-intersection v-show="param == 'active_account'">
      <ActiveAccount v-if="param == 'active_account'" :hash="hash" />
    </q-intersection>
  </q-page>
</template>
<script>
import Login from '@/components/auth/Login.vue';
import RegisterAccount from '@/components/auth/RegisterAccount.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import AccountMixin from '@/mixins/AccountMixin';
import ActiveAccount from '../components/auth/ActiveAccount';

export default {
  name: 'Auth',
  mixins: [AccountMixin],
  props: {
    param: {
      type: String,
      require: false,
      default: 'login'
    },
    hash: {
      type: String,
      require: false,
      default: null
    }
  },
  components: { Login, ForgotPassword, RegisterAccount, ActiveAccount },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style>
.auth .q-select__dropdown-icon {
  color: white;
}
</style>
